import axiosClient from "../apiClient";
import { SortPopoverType } from "../types/Popover";

export const getSwipeFileAdsList = async (action: SortPopoverType) => {
  try {
    let response;
    let reqParameters = {
      skip: action.skip,
      limit: action.limit,
      filters: {
        ...(Array.isArray(action.selectedBoardIds) &&
          action.selectedBoardIds.length > 0 && {
            boardId: action.selectedBoardIds,
          }),
        ...(Array.isArray(action.selectedPageIds) &&
          action.selectedPageIds.length > 0 && {
            pageId: action.selectedPageIds,
          }),
        ...(Array.isArray(action.selectedPlatformIds) &&
          action.selectedPlatformIds.length > 0 && {
            platforms: action.selectedPlatformIds,
          }),
        ...(Array.isArray(action.selectedTagIds) &&
          action.selectedTagIds.length > 0 && {
            tags: action.selectedTagIds,
          }),
        ...(action.userId && { userId: action.userId }),
        ...(action.folderId && { folderId: action.folderId }),
      },
      ...(action.search && { search: action.search }),
      sort: action.sort,
    };
    if (reqParameters) {
      response = await axiosClient.post("/ad/list", reqParameters);
    } else {
      response = await axiosClient.post("/ad/list");
    }
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteAdService = async (adId: string) => {
  try {
    const response = await axiosClient.delete(`/ad/${adId}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteMultipleAdService = async (adIds: string[]) => {
  try {
    const response = await axiosClient.delete(`/ad/delete/many`, {
      data: {
        adIds: adIds,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
