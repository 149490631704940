import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5.714rem 0;
  gap: 5.214rem;

  @media ( max-width: 575px ){
    flex-direction: column;
  }
`;

export const Organization = styled.div`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 20px;
  padding: 20px
`;

export const Title = styled.div`
  margin-top: 46px;
  font-size: 32px;
  font-weight: 600;
  line-height: 39.01px;
  text-align: center;
  color: ${({ theme }) => `${theme.colors.white}`};
`;

export const SubTitle = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${({ theme }) => `${theme.colors.white}`};
  width: 55%;
`;

export const ContentImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 46px;
`;