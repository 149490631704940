import { KeyboardEventHandler, useEffect, useMemo } from "react";
import {
  BrandCountText,
  BrandGridContainer,
  Container,
  HeaderSection,
  HeaderSubTitle,
  HeaderTitle,
  ListContainer,
  Tablecontainer,
} from "../style";
import { swipeFilesTabs } from "../../utils/constants";
import AdCard from "../../components/commonComponents/adCard";
import Header from "../../components/header";
import SearchAndFilter from "../../components/searchAndFilter";
import TabsHeader from "../../components/tabsHeader";
import AdTable from "../../components/commonComponents/adTable";
import CreateBoardModal from "../../components/modals/createBoardModal";
import TableToolbar from "../../components/tableToolbar";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";
import { SwipeFileSagaActions } from "../../store/swipeFile/sagas";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedTableItemsSelector,
  swipeFileBrandsParamsSelector,
  swipeFileFilterCountsSelector,
  swipeFileListSelector,
  swipeFileLoadingSelector,
  swipeFilePageSearchSelector,
  swipeFileSelectedBoardsSelector,
  swipeFileSelectedTagsSelector,
  swipeFileTabIndexSelector,
  swipeFileTopBrandsSelector,
  swipeFileViewTypeSelector,
  swipeFilesFavouriteBrandFilterSelector,
  swipefileSelectedSortOptionSelector,
} from "../../store/swipeFile/selector";
import { SwipeFileListItems, ViewTypeEnum } from "../../store/swipeFile/types";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import SaveAdPopover from "../../components/saveAdPopover";
import { Box, CircularProgress, Grid } from "@mui/material";
import BrandCard from "../../components/commonComponents/brandCards/brandCard";
import { BoardItem, brandItem, listType } from "../../store/metadata/types";
import CardListWrapper from "../../components/commonComponents/adCard/cardListWrapper";
import TopBrandCarousel from "../../components/commonComponents/brandCards/topBrandCarousel";
import {
  swipeFileSelectedBrandsSelector,
  swipeFileSelectedPlatformsSelector,
} from "../../store/swipeFile/selector";
import InfiniteScroll from "react-infinite-scroll-component";
import FavouriteBrandSwipeFile from "../../components/favouriteBrand/favouriteBrandSwipeFiles";
import AdSkeleton from "../../components/commonComponents/adSkeleton";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { CardContainer } from "../../components/commonComponents/adCard/style";
import DeleteAdModal from "../../components/modals/deleteAdModal";
import AddToBoardModal from "../../components/modals/addToBoardModal";
import AddTagModal from "../../components/modals/addTagModal";
import { useLocation } from "react-router-dom";

const content = {
  tabTitle: "Swipe Ad",
  tabSubTitle: "Your saved FB, Instagram & LinkedIn Ads",
};

const SwipeFiles = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector(swipeFileLoadingSelector);

  const selectedTableItems = useSelector(selectedTableItemsSelector);

  const viewType = useSelector(swipeFileViewTypeSelector);

  const swipeFileListItems = useSelector(swipeFileListSelector);

  const swipeFileTopBrandLists = useSelector(swipeFileTopBrandsSelector);

  const userDetails = JSON.parse(localStorage.getItem("User") as string);

  const selectedBrands = useSelector(swipeFileSelectedBrandsSelector);

  const selectedPlatforms = useSelector(swipeFileSelectedPlatformsSelector);

  const selectedBoards = useSelector(swipeFileSelectedBoardsSelector);

  const selectedTags = useSelector(swipeFileSelectedTagsSelector);

  const swipefileSelectedSortOption = useSelector(
    swipefileSelectedSortOptionSelector
  );

  const swipeFileTabIndex = useSelector(swipeFileTabIndexSelector);
  const favouriteBrandFilterEnabled = useSelector(
    swipeFilesFavouriteBrandFilterSelector
  );
  const swipeFileBrandsParams = useSelector(swipeFileBrandsParamsSelector);
  const swipeFileFilterCounts = useSelector(swipeFileFilterCountsSelector);

  const searchValue = useSelector(swipeFilePageSearchSelector);

  const handleViewAdsButtonClicked = (
    brandId: string,
    handleLoadingState: Function
  ) => {
    dispatch(
      SwipeFileSagaActions.fetchBrandSwipeFileItems({
        brandId,
        handleLoadingState,
      })
    );
  };

  const handleViewBrandAds = (brandId: string) => {
    dispatch(
      SwipeFileSagaActions.fetchSelectedBrandSwipeFileItems({
        brandId,
      })
    );
  };

  const setSwipeFileTabIndex = (tabIndex: number) => {
    dispatch(SwipeFileReducerAction.setSwipeFileTabIndex(tabIndex));
    if (tabIndex === 1) {
      dispatch(
        MetaDataSagaActions.fetchBrandLists({ userId: userDetails?.user?._id })
      );
      dispatch(
        SwipeFileSagaActions.fetchSwipeFileTopBrandList(swipeFileBrandsParams)
      );
    } else {
      dispatch(SwipeFileReducerAction.resetSwipefileState());
      dispatch(
        SwipeFileSagaActions.fetchSwipeFileList({
          userId: userDetails?.user?._id,
        })
      );
    }
  };

  const fetchSortingSwipeFileItems = (
    sortValue: string,
    sortObject: Object
  ) => {
    dispatch(
      SwipeFileReducerAction.setSelectedSortOption({ sortValue, sortObject })
    );
    dispatch(SwipeFileSagaActions.fetchSortedSwipeFileList());
  };

  const fetchfilterSwipeFileItems = (
    selectAll: boolean,
    checked: boolean,
    stateName: string,
    list: listType[],
    id?: string
  ) => {
    dispatch(
      SwipeFileSagaActions.applyFilterOnSwipeFileList({
        selectAll,
        checked,
        id,
        list,
        stateName,
      })
    );
  };

  const refetchSwipeFileItem = (adId: string, updatedBoardList: BoardItem) => {
    dispatch(
      SwipeFileReducerAction.setUpdatedBoardIds({ adId, updatedBoardList })
    );
  };

  const handleClearSwipeFileFilter = () => {
    dispatch(SwipeFileReducerAction.resetSwipefileState());
    dispatch(
      SwipeFileSagaActions.fetchSwipeFileList({
        userId: userDetails?.user?._id,
      })
    );
  };

  useEffect(() => {
    if (location.hash.split("?")[1] === undefined){
      dispatch(
        SwipeFileSagaActions.fetchSwipeFileList({
          userId: userDetails?.user?._id,
        })
      );
    }
    dispatch(MetaDataSagaActions.fetchFavouriteFolderList());
    dispatch(MetaDataSagaActions.fetchFavouriteBrands());
    dispatch(MetaDataSagaActions.fetchBoardList());
    dispatch(MetaDataSagaActions.fetchTagLists());
    
    return () => {
      dispatch(SwipeFileReducerAction.resetSwipefileState());
      dispatch(MetaDataReducerAction.setBrandFilterSearchText(""));
    };
  }, [dispatch]);

  useEffect(() => {
    if (location.hash.split("?")[1] !== undefined){
      dispatch(
        SwipeFileSagaActions.fetchSelectedBoardAds({
          boardId: location.hash.split("?")[1]
        })
      );
    }
  }, [dispatch,location.hash]);

  const fetchMoreBrands = () => {
    dispatch(
      SwipeFileSagaActions.fetchSwipeFileTopBrandList({
        ...swipeFileBrandsParams,
        skip: swipeFileBrandsParams.skip + swipeFileBrandsParams.limit,
      })
    );
  };

  const fetchMoreSwipeFileItem = () => {
    dispatch(
      SwipeFileSagaActions.fetchSwipeFileList({
        userId: userDetails?.user?._id,
      })
    );
  };

  const handleSearchChange = (value: string) => {
    dispatch(SwipeFileReducerAction.setSwipeFilePageSearchInputValue(value));
  };

  const handleScrollForSearchBrand = () => {
    window.scrollTo({
      top: 700,
      behavior: "smooth",
    });
  };

  const hanldeSearchButton = () => {
    if (swipeFilesTabs[swipeFileTabIndex].id === "my-ads" && location.hash.split("?")[1] === undefined) {
      dispatch(SwipeFileSagaActions.fetchSearchedSwipeFileItema());
    } else if (swipeFilesTabs[swipeFileTabIndex].id === "brands") {
      dispatch(
        SwipeFileSagaActions.fetchSwipeFileSearchTopBrandList({
          handleScroll: handleScrollForSearchBrand,
        })
      );
    }
  };

  const handleSwipeFilesBrandFavouriteFilter = () => {
    dispatch(
      SwipeFileReducerAction.setSwipeFileFavouriteBrandFilter(
        !favouriteBrandFilterEnabled
      )
    );
    dispatch(
      SwipeFileSagaActions.fetchSwipeFileTopBrandList({
        ...swipeFileBrandsParams,
        skip: 0,
        favourite: !favouriteBrandFilterEnabled,
      })
    );
  };

  const handleSearchText: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter") {
      hanldeSearchButton();
    }
  };

  const handleDelete = () => {
    dispatch(SwipeFileSagaActions.deleteAd());
  };

  const handleDeleteMultipleAds = () => {
    dispatch(SwipeFileSagaActions.deleteMultipleAds());
  };
  const RenderAdsComponent = useMemo(() => {
    const swipeFileListItemsLength = swipeFileListItems?.data?.length ?? 0;
    const hasMoreData = swipeFileListItems?.total > swipeFileListItemsLength;

    if (!isLoading && swipeFileListItemsLength === 0) {
      return (
        <ListContainer>
          <HeaderTitle textAlign="center">
            Your saved Ads will appear here
          </HeaderTitle>
          <HeaderSubTitle textAlign="center">
            Use our extension to save ads from platforms and grow
          </HeaderSubTitle>
        </ListContainer>
      );
    }
    return (
      <>
        {swipeFileFilterCounts === 0 && <FavouriteBrandSwipeFile />}
        {viewType === ViewTypeEnum.Card && (
          <ListContainer>
            <CardListWrapper>
              {swipeFileListItems?.data?.map(
                (item: SwipeFileListItems, index: number) => (
                  <AdCard
                    item={item}
                    key={index}
                    index={index}
                    dataList={swipeFileListItems?.data}
                    handleViewAd={handleViewBrandAds}
                    locationPathname={location.pathname}
                  />
                )
              )}
              {(isLoading || hasMoreData) &&
                [...Array(4)]?.map((index) => (
                  <AdCard item={{} as SwipeFileListItems} key={index} />
                ))}
            </CardListWrapper>
            <InfiniteScroll
              dataLength={swipeFileListItemsLength ?? 0}
              next={fetchMoreSwipeFileItem}
              hasMore={hasMoreData}
              loader={
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              }
              style={{ overflow: "hidden" }}
              key={swipeFileListItemsLength}
            >
              <></>
            </InfiniteScroll>
          </ListContainer>
        )}

        {viewType === ViewTypeEnum.List && (
          <Tablecontainer id="scrollableDiv">
            <InfiniteScroll
              dataLength={swipeFileListItemsLength}
              next={fetchMoreSwipeFileItem}
              hasMore={hasMoreData}
              loader={
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              }
              style={{ overflow: "unset" }}
              key={swipeFileListItemsLength}
              scrollableTarget="scrollableDiv"
            >
              <AdTable />
            </InfiniteScroll>
          </Tablecontainer>
        )}
      </>
    );
  }, [
    swipeFileListItems?.data,
    swipeFileListItems?.total,
    viewType,
    isLoading,
  ]);

  const RenderBrandsComponent = useMemo(() => {
    const swipeFileTopBrandListsLength =
      swipeFileTopBrandLists?.data?.length ?? 0;

    if (isLoading) {
      return (
        <ListContainer sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </ListContainer>
      );
    }
    if (favouriteBrandFilterEnabled && swipeFileTopBrandListsLength === 0) {
      return (
        <ListContainer>
          <HeaderTitle textAlign="center">No favoutite brands.</HeaderTitle>
        </ListContainer>
      );
    }
    if (swipeFileTopBrandListsLength === 0) {
      return (
        <ListContainer>
          <HeaderTitle textAlign="center">
            Your saved Ad's brand will appear here
          </HeaderTitle>
          <HeaderSubTitle textAlign="center">
            Use our extension to save ads from platforms and grow
          </HeaderSubTitle>
        </ListContainer>
      );
    }
    return (
      <ListContainer>
        {!favouriteBrandFilterEnabled && (
          <TopBrandCarousel
            brandsTitle="Brands of Ads You Saved the Most"
            topBrandLists={swipeFileTopBrandLists?.top10}
            handleViewAd={handleViewAdsButtonClicked}
          />
        )}
        {swipeFileTopBrandListsLength > 1 && (
          <BrandCountText>{swipeFileTopBrandListsLength} Brands</BrandCountText>
        )}
        <InfiniteScroll
          dataLength={swipeFileTopBrandListsLength}
          next={fetchMoreBrands}
          hasMore={swipeFileTopBrandLists?.total > swipeFileTopBrandListsLength}
          loader={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          }
          style={{ overflow: "hidden" }}
        >
          <BrandGridContainer container spacing={1.25}>
            {swipeFileTopBrandLists?.data?.map(
              (item: brandItem, index: number) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <BrandCard
                    item={item}
                    handleViewAd={handleViewAdsButtonClicked}
                  />
                </Grid>
              )
            )}
          </BrandGridContainer>
        </InfiniteScroll>
      </ListContainer>
    );
  }, [
    swipeFileTopBrandLists?.data,
    swipeFileTopBrandLists?.total,
    favouriteBrandFilterEnabled,
    isLoading,
  ]);

  const swipeFilesSections: {
    [key: number]: JSX.Element;
  } = {
    0: <>{RenderAdsComponent}</>,
    1: <>{RenderBrandsComponent}</>,
  };

  return (
    <Container>
      <HeaderSection>
        <Header content={content} />
        {selectedTableItems.length > 1 ? (
          <TableToolbar />
        ) : (
          <>
            <SearchAndFilter
              handleFilter={fetchfilterSwipeFileItems}
              selectedBrands={selectedBrands}
              selectedPlatforms={selectedPlatforms}
              handleSortFilter={fetchSortingSwipeFileItems}
              selectedFilterOption={swipefileSelectedSortOption}
              renderBoardFilter={true}
              selectedBoards={selectedBoards}
              searchValue={searchValue}
              handleSearchChange={handleSearchChange}
              hanldeSearchButton={hanldeSearchButton}
              buttonsToShow={
                swipeFilesTabs[swipeFileTabIndex].searchAndFilterButtons
              }
              favouriteBrandFilter={{
                value: favouriteBrandFilterEnabled,
                setValue: handleSwipeFilesBrandFavouriteFilter,
              }}
              filterCounts={swipeFileFilterCounts}
              handleClearFilter={handleClearSwipeFileFilter}
              userId={userDetails?.user?._id}
              handleKeyDown={handleSearchText}
              renderTagFilter={true}
              selectedTags={selectedTags}
            />
            <TabsHeader
              tabIndex={swipeFileTabIndex}
              data={swipeFilesTabs}
              setTabIndex={setSwipeFileTabIndex}
            />
          </>
        )}

        {swipeFilesSections[swipeFileTabIndex]}
      </HeaderSection>
      <SaveAdPopover reFetchData={refetchSwipeFileItem} />
      <CreateBoardModal />
      <AddToBoardModal />
      <DeleteAdModal
        handleDelete={handleDelete}
        handleMultipleDelete={handleDeleteMultipleAds}
      />
      <AddTagModal />
    </Container>
  );
};

export default SwipeFiles;
