import styled from "styled-components";

export const Container = styled.div`
    max-width: calc(100% - 360px);
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1800px) {
        max-width: calc(100% - 260px);
    }
    
    @media (max-width: 900px) {
        max-width: calc(100% - 160px);
    }

    @media (max-width: 575px) {
        max-width: calc(100% - 60px);
    }
        
`;
