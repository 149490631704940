import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Container,
  ContentDescription,
  ContentTitle,
  ImageContainer,
  SocialCard,
  SocialContent,
  SocialImage,
} from "./style";
import socialImage from "../../../assets/images/social-image1.png";
import socialImage2 from "../../../assets/images/social-image2.png";
import socialImage3 from "../../../assets/images/social-image3.png";


export default function ResponsiveGrid() {
  return (
    <Container>
      <Box sx={{ flexGrow: 1 }} marginTop={"4.286rem"}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <SocialCard>
              <ImageContainer>
                <SocialImage src={socialImage} alt="social image" />
              </ImageContainer>
              <SocialContent>
                <ContentTitle>Social Media Monitoring</ContentTitle>
                <ContentDescription>
                  Lorem ipsum dolor sit amet consectetur. Id erat libero magna
                  eu enim accumsan.
                </ContentDescription>
              </SocialContent>
            </SocialCard>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <SocialCard>
              <SocialContent isImageAbove={true}>
                <ContentTitle>Social Media Monitoring</ContentTitle>
                <ContentDescription>
                  Lorem ipsum dolor sit amet consectetur. Id erat libero magna
                  eu enim accumsan.
                </ContentDescription>
              </SocialContent>
              <ImageContainer isImageAbove={true}>
                <SocialImage src={socialImage2} alt="social image" />
              </ImageContainer>
            </SocialCard>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <SocialCard>
              <ImageContainer>
                <SocialImage src={socialImage3} alt="social image" />
              </ImageContainer>
              <SocialContent>
                <ContentTitle>Social Media Monitoring</ContentTitle>
                <ContentDescription>
                  Lorem ipsum dolor sit amet consectetur. Id erat libero magna
                  eu enim accumsan.
                </ContentDescription>
              </SocialContent>
            </SocialCard>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
