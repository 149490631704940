import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5.714rem 0;
  gap: 5.214rem;

  @media ( max-width: 575px ){
    flex-direction: column;
  }
`;

export const HeroImage = styled.div`
  width: 60%;
  @media ( max-width: 575px ){
      width: 100%;
  }
`;

export const LoungeTitle = styled.div`
  position: absolute;
  margin-left: 16px;
  margin-top: 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: 29.26px;
  text-align: left;
  color: ${({ theme }) => `${theme.colors.white}`};
  
  @media ( max-width: 1300px ){
    font-size: 20px
  }
  
  @media ( max-width: 1200px ){
    font-size: 16px
  }

  @media ( max-width: 1070px ){
    font-size: 14px;
    margin-top: 5px;
  }

  @media ( max-width: 790px ){
    font-size: 11px;
    margin-top: 2px;
  }

  @media ( max-width: 650px ){
    font-size: 9px;
    margin-top: 0;
    margin-left: 10px;
  }

  @media ( max-width: 575px ){
    font-size: 10px;
    margin-top: 5px;
    margin-left: 10px;
  }
`;

export const ContentImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0px 16px 40px 0px ${({ theme }) => theme.colors.darkBlue};
  box-sizing: border-box;
`;