import {
  SearchFilterSection,
  SearchInput,
  SearchIconWrapper,
  SearchWrapper,
  OptionSection,
  ViewTypeSelection,
  ViewButton1,
  ViewButton2,
  OptionButtonText,
  OptionButton,
  AppliedFilterText,
} from "../../pageComponents/style";
import SearchIcon from "@mui/icons-material/Search";
import {
  ArrowIcon,
  AtoZIcon,
  FavouriteIcon,
  FilterIcon,
  GridViewIcon,
  HeartIcon,
  ListViewIcon,
  VisibleIcon,
} from "../../assets/Icons";
import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { Box, Divider } from "@mui/material";
import MenuPopover from "../popover";
import { useTheme } from "styled-components";
import SortPopover from "../sortPopover";
import { useDispatch, useSelector } from "react-redux";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";
import { ViewTypeEnum } from "../../store/discovery/types";
import { swipeFileViewTypeSelector } from "../../store/swipeFile/selector";
import useScrollingUp from "../../hooks/useScrollingUp";
import useConfig from "../../hooks/useConfig";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import useDebounce from "../../hooks/debounce";

const SearchAndFilter = ({
  handleFilter,
  selectedBrands,
  selectedPlatforms,
  handleSortFilter,
  selectedFilterOption,
  renderBoardFilter,
  selectedBoards,
  searchValue,
  handleSearchChange,
  hanldeSearchButton,
  buttonsToShow,
  favouriteBrandFilter,
  filterCounts,
  handleClearFilter,
  userId,
  handleKeyDown = () => {},
  renderTagFilter,
  selectedTags,
}: {
  handleFilter: Function;
  selectedBrands: string[];
  selectedPlatforms: string[];
  handleSortFilter: Function;
  selectedFilterOption: string;
  renderBoardFilter?: boolean;
  selectedBoards?: string[];
  searchValue?: string;
  handleSearchChange?: Function;
  hanldeSearchButton?: Function;
  buttonsToShow?: string[];
  favouriteBrandFilter?: { value: boolean; setValue: () => void };
  filterCounts: number;
  handleClearFilter: () => void;
  userId?: string;
  handleKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  renderTagFilter?: boolean;
  selectedTags?: string[];
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [sortAnchorEl, setSortAnchorEl] = useState<Element | null>(null);
  const dispatch = useDispatch();
  const { scrollRef, isSticky } = useScrollingUp();
  const { drawerOpen } = useConfig();
  const popupRef = useRef<HTMLDivElement>(null);
  const [localSearchValue, setLocalSearchValue] = useState(searchValue ?? "");
  const debouncedSearchValue = useDebounce(localSearchValue, 1000); // 1.5 seconds debounce

  useEffect(() => {
    if (!isSticky) {
      anchorEl && setAnchorEl(scrollRef.current);
      sortAnchorEl && setSortAnchorEl(scrollRef.current);
    }
  }, [isSticky]);

  // We might use it in future
  // useEffect(() => {
  //   if (debouncedSearchValue !== null) {
  //     hanldeSearchButton && hanldeSearchButton();
  //   }
  // }, [debouncedSearchValue]);

  const handlePopover = () => {
    setAnchorEl(isSticky ? popupRef.current : scrollRef.current);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleViewButtonClick = (viewType: ViewTypeEnum) => {
    dispatch(SwipeFileReducerAction.setViewType(viewType));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLocalSearchValue(value);
    handleSearchChange && handleSearchChange(value);
  };

  const handleSearchClick = () => {
    hanldeSearchButton && hanldeSearchButton();
  };

  const clearAllFilters = () => {
    handleClearFilter();
    dispatch(MetaDataSagaActions.clearAllFilter({ userId }));
  };

  const viewType = useSelector(swipeFileViewTypeSelector);

  const HEADER_BUTTONS: {
    [key: string]: JSX.Element;
  } = {
    users: (
      <OptionButton
        onClick={() => {
          dispatch(MetaDataReducerAction.resetSkipLimitValue());
          dispatch(
            MetaDataReducerAction.setIsRemoveUserModalOpen({
              isOpen: true,
              isUser: true,
            })
          );
        }}
      >
        <OptionButtonText>Users</OptionButtonText>
        <ArrowIcon />
      </OptionButton>
    ),
    filter: (
      <Box>
        <OptionButton>
          <Box
            onClick={handlePopover}
            display={"flex"}
            gap={"0.625rem"}
            alignItems={"center"}
          >
            <FilterIcon />
            <OptionButtonText>Filters</OptionButtonText>
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem />
          <AppliedFilterText
            color={filterCounts ? theme.colors.blue : theme.colors.gray1}
            onClick={() => filterCounts && clearAllFilters()}
          >
            {filterCounts ? `Clear ${filterCounts}` : "No filters applied"}
          </AppliedFilterText>
        </OptionButton>
      </Box>
    ),
    favourite: (
      <OptionButton onClick={favouriteBrandFilter?.setValue}>
        {favouriteBrandFilter?.value ? <FavouriteIcon /> : <HeartIcon />}
        <OptionButtonText>Favourite</OptionButtonText>
      </OptionButton>
    ),
    visible: (
      <OptionButton>
        <VisibleIcon />
        <OptionButtonText>Visible</OptionButtonText>
        <ArrowIcon />
      </OptionButton>
    ),
    sort: (
      <OptionButton
        onClick={(event) =>
          setSortAnchorEl(isSticky ? popupRef.current : scrollRef.current)
        }
      >
        <AtoZIcon />
        <OptionButtonText>Sort by</OptionButtonText>
        <ArrowIcon />
      </OptionButton>
    ),
    viewSelection: (
      <ViewTypeSelection>
        <ViewButton1
          selected={viewType === ViewTypeEnum.List}
          onClick={() => handleViewButtonClick(ViewTypeEnum.List)}
        >
          <ListViewIcon selected={viewType === ViewTypeEnum.List} />
        </ViewButton1>
        <ViewButton2
          selected={viewType === ViewTypeEnum.Card}
          onClick={() => handleViewButtonClick(ViewTypeEnum.Card)}
        >
          <GridViewIcon selected={viewType === ViewTypeEnum.Card} />
        </ViewButton2>
      </ViewTypeSelection>
    ),
  };

  const renderContent = () => {
    return (
      <>
        <SearchWrapper variant="outlined">
          <SearchInput
            placeholder="AI Search..."
            value={searchValue ?? ""}
            onChange={handleChange}
            sx={{
              input: {
                "&::placeholder": {
                  color: theme.colors.gray1,
                  fontWeight: 500,
                  opacity: 1,
                },
              },
            }}
            endAdornment={
              <SearchIconWrapper
                position="end"
                sx={{
                  cursor: "pointer",
                }}
                onClick={handleSearchClick}
              >
                <SearchIcon />
              </SearchIconWrapper>
            }
          />
        </SearchWrapper>
        <OptionSection>
          {Object.keys(HEADER_BUTTONS).map((buttonName) =>
            buttonsToShow?.includes(buttonName) &&
            HEADER_BUTTONS[buttonName] ? (
              HEADER_BUTTONS[buttonName]
            ) : (
              <></>
            )
          )}
        </OptionSection>
      </>
    );
  };

  return (
    <>
      {isSticky && (
        <SearchFilterSection
          isSticky={isSticky}
          drawerOpen={drawerOpen}
          ref={popupRef}
        >
          {renderContent()}
        </SearchFilterSection>
      )}
      <SearchFilterSection ref={scrollRef}>
        {renderContent()}
      </SearchFilterSection>
      <MenuPopover
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
        handleFilter={handleFilter}
        selectedBrands={selectedBrands}
        selectedPlatforms={selectedPlatforms}
        renderBoardFilter={renderBoardFilter}
        selectedBoards={selectedBoards}
        userId={userId}
        renderTagFilter={renderTagFilter}
        selectedTags={selectedTags}
      />
      <SortPopover
        anchorEl={sortAnchorEl}
        handlePopoverClose={() => setSortAnchorEl(null)}
        handleSortFilter={handleSortFilter}
        selectedFilterOption={selectedFilterOption}
      />
    </>
  );
};

export default SearchAndFilter;
