import axios from "axios";
import axiosClient from "../apiClient";
import { starredListsParamsType } from "../store/starred/types";

export const getAdByIdService = async (id: string) => {
  try {
    const response = await axiosClient.get(`/ad/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const rateAdByIdService = async (params: {
  adId: string;
  rating: number;
}) => {
  try {
    const response = await axiosClient.post(`/ad/rate`, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getStarredAdList = async (data: starredListsParamsType) => {
  try {
    const response = await axiosClient.get(`/ad/list/started/data?skip=${
      data.skip
    }&limit=${data.limit}
    ${data.search ? `&search=${data.search}` : ``}
    `);
    return response;
  } catch (error) {
    return error;
  }
};

export const downloadMedia = async (adId: string, page: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/ad/downloadMedia/${adId}`,
      {
        headers: {
          Authorization: localStorage.getItem("AccessToken"),
          "Content-Type": "application/zip",
        },
        responseType: "blob",
      }
    );
    const blob = new Blob([response.data], { type: "application/zip" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${page}.zip`;

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    return response;
  } catch (error) {
    return error;
  }
};

export const saveTranscript = async (params: {
  name: string;
  adId: string;
}) => {
  try {
    const response = await axiosClient.post(`/transcript`, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const manualUploadAds = async (body: {
  boardIds: string[];
  media: File;
}) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: localStorage.getItem("AccessToken"),
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/ad/manual-ad`,
      body,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const GenerateTranscriptService = async (adId: string) => {
  try {
    const response = await axiosClient.post("/transcript/generate", {
      adId: adId,
    });
    return response;
  } catch (error) {
    return error;
  }
};
