import axiosClient from "../apiClient";
import { SaveAdToBoardsType, createBoardParamType } from "../types/BoardTypes";
import * as qs from "qs";

export const BoardService = async (params?: { search?: string }) => {
  try {
    const filterObject = {
      ...(params &&
        params?.search && { search: params?.search }),
    };
    const queryString = qs.stringify(filterObject);
    const apiUrl = queryString ? `/board?${queryString}` : `/board`;
    const response = await axiosClient.get(apiUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const CreateBoardService = async (params: createBoardParamType) => {
  try {
    const response = await axiosClient.post("/board", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const SaveAdToBoardsService = async (params: SaveAdToBoardsType) => {
  try {
    const response = await axiosClient.put("/board/add", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const AddMultipleBoardsToAdsService = async (params: {
  adIds: string[];
  boardIds: string[];
}) => {
  try {
    const response = await axiosClient.post("/ad/multiple-ad-to-board", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const UpdateBoardService = async (params: {
  boardId: string;
  name: string;
}) => {
  try {
    const response = await axiosClient.put("/board/update/boardName", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const DeleteBoardService = async (boardId: string) => {
  try {
    const response = await axiosClient.delete(`/board/delete/${boardId}`);
    return response;
  } catch (error) {
    return error;
  }
};
