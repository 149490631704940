import {
  Container,
  ContentDescription,
  ContentImage,
  ExploreLoungeImage,
  MainContent,
  Content,
  ContentTitle,
  CtaButton,
  Tab,
  TabIcon,
  TabText,
} from "./style";
import LandingImage from "../../../assets/images/landing-image.png";
import { Grid } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";

const tabData = [
  { icon: <CalendarTodayOutlinedIcon />, text: "Sort by Dates" },
  { icon: <BusinessOutlinedIcon />, text: "Filter by Brands" },
  { icon: <SearchRoundedIcon />, text: "Search by Ad Description" },
  { icon: <InsertLinkOutlinedIcon />, text: "Filter by Platforms" },
  {
    icon: <FolderCopyOutlinedIcon />,
    text: "Easy access to your favorite folders",
  },
];

const ExploreLoungeSection = () => {
  return (
    <Container>
      <Content>
        <MainContent>
          <ContentTitle>Explore Lounge</ContentTitle>
          <ContentDescription>
            Discover Ad from the Pool of Ads saved by all Users
          </ContentDescription>
        </MainContent>
        <CtaButton>Get Started</CtaButton>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {tabData.map(({ icon, text }, index) => (
            <Grid key={index} item md={12} lg={6}>
              <Tab>
                <TabIcon>{icon}</TabIcon>
                <TabText>{text}</TabText>
              </Tab>
            </Grid>
          ))}
        </Grid>
      </Content>
      <ExploreLoungeImage>
        <ContentImage src={LandingImage} alt="Platform illustration" />
      </ExploreLoungeImage>
    </Container>
  );
};

export default ExploreLoungeSection;
