import Logo from "../../../assets/images/Logo.png";
import routeConstant from "../../../utils/routeConstant";
import {
  Container,
  NavActions,
  Navigation,
  PrimaryButton,
  NavMenuSection,
  NavMenuItem,
  BrandLogo,
} from "./style";

const LandingNavbar = () => {
  return (
    <Container>
      <Navigation>
        <BrandLogo src={Logo} alt="brand-logo" />
        <NavMenuSection>
          <NavMenuItem>Services</NavMenuItem>
          <NavMenuItem>About Platform</NavMenuItem>
        </NavMenuSection>
      </Navigation>
      <NavActions>
        <NavMenuItem to={`/${routeConstant.login}`}>Log in</NavMenuItem>
        <PrimaryButton>Get Started</PrimaryButton>
      </NavActions>
    </Container>
  );
};

export default LandingNavbar;
