import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  BoardIcon,
  CreateNewIcon,
  DeleteIcon,
  EditIcon,
  EllipsisIcon,
  EllipsisIconLight,
  OpenFolder,
  ShareIcon,
  StarredIcon,
} from "../../assets/Icons";
import { FolderListItem, PanelItem } from "../../pageComponents/auth/style";
import useConfig from "../../hooks/useConfig";
import { folderItem, selectFolderBoardItem } from "../../store/metadata/types";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFolderLoadingSelector,
  folderListsSelector,
  selectFolderSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import routeConstant from "../../utils/routeConstant";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { SwipeFileSagaActions } from "../../store/swipeFile/sagas";
import { useTheme } from "styled-components";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";
import { selectedSidebarBoardIdSelector } from "../../store/swipeFile/selector";
import { useEffect, useRef, useState } from "react";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { defaultFolderName } from "../../utils/constants";

const SidebarFolder = () => {
  const { drawerOpen } = useConfig();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const folderList = useSelector(folderListsSelector);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedFolder = useSelector(selectFolderSelector);
  const selectedSidebarBoardId = useSelector(selectedSidebarBoardIdSelector);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [prevFolderCount, setPrevFolderCount] = useState(folderList?.userFolders);
  const loading = useSelector(deleteFolderLoadingSelector);

  const handleAnClick = (
    event: React.MouseEvent<HTMLElement>,
    item: folderItem
  ) => {
    setAnchorEl(event.currentTarget);
    dispatch(MetaDataReducerAction.resetSkipLimitValue());
    dispatch(MetaDataReducerAction.setFolderItem(item));
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(MetaDataReducerAction.setFolderItem(null));
  };

  const handleMenuItemClick = () => {
    dispatch(
      MetaDataReducerAction.setIsRemoveUserModalOpen({
        isOpen: true,
        isUser: false,
      })
    );
    setAnchorEl(null);
  };

  const handleEditFolder = () => {
    dispatch(MetaDataReducerAction.setOpenCreateFolder(true));
    setAnchorEl(null);
  }

  const handleOpen = (folderId: string) => {
    dispatch(MetaDataReducerAction.setSelectedFolder(folderId));
  };

  const handleRedirectToSwipefile = (boardId: string) => {
    navigate(`${routeConstant.swipeFiles}#my-ads?${boardId}`);
  };

  const handleClick = (boardId: string) => {
    dispatch(SwipeFileReducerAction.setSelectedSidebarBoardId(boardId));
    handleRedirectToSwipefile(boardId);
  };

  const closeAnchorElAfterDelete = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    dispatch(
      MetaDataSagaActions.deleteFolder({
        closeAnchorElAfterDelete: closeAnchorElAfterDelete,
      })
    );
  };

  const constants = {
    myFolders: "My Folders",
  };
  const selectedFolderId = location.pathname.split("/")[2];
  const selectedFolderName = location.pathname.split("/")[1];
  const isSelectedInCurrentFolder =
    selectedFolderName === Object.keys(constants)[0];

  const handleNavigate = (id: string, item: folderItem) => {
    dispatch(MetaDataReducerAction.resetSwipefileState());
    dispatch(MetaDataReducerAction.setSelectedFolderId(id));
    dispatch(MetaDataReducerAction.setSelectedFolder(item));
    dispatch(MetaDataReducerAction.setSelectedTabIndex(0));
    navigate(`${routeConstant.myFolders}/${id}`, {
      state:{
        type: "sidebarFolder",
      }
    });
  };

  useEffect(() => {
    const currentFolderCount = folderList?.userFolders?.length || 0;

    // Only scroll to bottom if a new folder is added after the initial load
    if (currentFolderCount > prevFolderCount && prevFolderCount !== 0 && scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }

    // Update the previous folder count
    setPrevFolderCount(currentFolderCount);
  }, [folderList?.userFolders]);

  return (
    <>
      <Divider sx={{ borderColor: "#2f4757" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1.5rem 1.5rem 0.5rem 1.8rem",
        }}
      >
        <span>{constants?.myFolders}</span>
        <div
          onClick={() => {
            dispatch(MetaDataReducerAction.setOpenCreateFolder(true));
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <CreateNewIcon fill="white" />
        </div>
      </div>

      <Box
        ref={scrollRef}
        sx={{
          marginLeft: "1rem",
          marginBottom: "1rem",
          height: "100%",
          maxHeight: {
            xs: "47vh",
            sm: "39vh",
            md: "32vh",
            lg: "27vh",
            xl: "27vh",
          },
          overflowY: "auto",
          overflowX: "hidden",
          "::-webkit-scrollbar": {
            width: "4px",
          },
        }}
      >
        <PanelItem
          drawerOpen={drawerOpen}
          to={`${routeConstant.starred}`}
          style={{
            background:
              location.pathname === routeConstant.starred
                ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                : "transparent",
          }}
        >
          <StarredIcon />
          {drawerOpen && <Typography marginLeft={1}>Starred </Typography>}
        </PanelItem>

        <Box
          sx={{
            marginTop: "-0.5rem",
          }}
        >
          {folderList?.userFolders?.length > 0 &&
            folderList?.userFolders?.map((item: folderItem) => (
              <div>
                <FolderListItem
                  key={item._id}
                  style={{
                    borderRadius: "4px",
                    background:
                      selectedFolderId === item._id && isSelectedInCurrentFolder
                        ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                        : "transparent",
                  }}
                >
                  <OpenFolder />
                  {drawerOpen && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => selectedFolderId !== item?._id && handleNavigate(item?._id, item)}
                        style={{
                          marginLeft: 10,
                          cursor: selectedFolderId !== item._id ? "pointer" : "default",
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        {item.name}
                      </div>

                      {item.name !== defaultFolderName && (
                        <div
                          onClick={(event) => handleAnClick(event, item)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {selectedFolderId === item._id &&
                          isSelectedInCurrentFolder ? (
                            <EllipsisIconLight />
                          ) : (
                            <EllipsisIcon />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </FolderListItem>
              </div>
            ))}
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          ".MuiMenu-paper": {
            borderRadius: "0.625rem",
          },
          ".MuiList-root": {
            padding: "0",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <MenuItem
          onClick={handleEditFolder}
          style={{
            gap: "0.75rem",
            padding: "14px 15px",
            borderBottom: "1px solid #E3E6EA",
          }}
        >
          <EditIcon />
          <Typography
            fontSize="1rem"
            fontWeight="500"
            color={theme.colors.black2}
            style={{
              cursor: "pointer",
            }}
          >
            Edit
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={handleDelete}
          style={{
            gap: "0.75rem",
            padding: "14px 15px",
            borderBottom: "1px solid #E3E6EA",
          }}
        >
          {loading ? (
            <CircularProgress size={20} sx={{ color: theme.colors.black2 }} />
          ) : (
            <DeleteIcon />
          )}
          <Typography
            fontSize="1rem"
            fontWeight="500"
            style={{
              cursor: "pointer",
            }}
          >
            Delete
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={handleMenuItemClick}
          style={{
            gap: "0.75rem",
            padding: "14px 15px",
          }}
        >
          <ShareIcon />
          <Typography
            fontSize="1rem"
            fontWeight="500"
            color={theme.colors.black2}
            style={{
              cursor: "pointer",
            }}
          >
            Share
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SidebarFolder;
