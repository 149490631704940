import { styled } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const CustomTooltip = styled(({ className, id = '', ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme, id }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      borderTop: `0.15rem solid #1C75BC`,
      color: "black",
      fontSize: id ==="ad_card" ? "10px" : "12px",
      borderRadius: "8px",
      padding: "10px",
      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    },
  }));