import axiosClient from "../apiClient";
import { SortPopoverType } from "../types/Popover";

export const getCommunityAdsList = async (action: SortPopoverType) => {
  try {
    let response;
    let reqParameters = {
      skip: action.skip,
      limit: action.limit,
      team: true,
      filters: {
        ...(Array.isArray(action.selectedBoardIds) &&
          action.selectedBoardIds.length > 0 && {
            boardId: action.selectedBoardIds,
          }),
        ...(Array.isArray(action.selectedPageIds) &&
          action.selectedPageIds.length > 0 && {
            pageId: action.selectedPageIds,
          }),
        ...(Array.isArray(action.selectedPlatformIds) &&
          action.selectedPlatformIds.length > 0 && {
            platforms: action.selectedPlatformIds,
          }),
        ...(Array.isArray(action.selectedTagIds) &&
          action.selectedTagIds.length > 0 && {
            tags: action.selectedTagIds,
          }),

      },
      ...(action.search && { search: action.search }),
      sort: action.sort,
      userId: action.userId
    };

    if (reqParameters) {
      response = await axiosClient.post("/ad/list", reqParameters);
    }
    return response;
  } catch (error) {
    return error;
  }
};
