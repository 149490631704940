import { createSlice } from "@reduxjs/toolkit";
import { AccountStateType } from "./types";
import { monthlyPlanType } from "../../utils/constants";

export const initialState: AccountStateType = {
  isLoading: false,
  userDetails: null,
  userPhoneNumber: "",
  openResetPasswordModal: false,
  planLists: [],
  isPlanLoading: false,
  selectPlanType: monthlyPlanType,
  selectPlanList: [],
  activePlan: {},
};

const account = createSlice({
  name: "accountState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },
    setUserPhoneNumber(state, action) {
      state.userPhoneNumber = action.payload;
    },
    setOpenResetPasswordModal(state, action) {
      state.openResetPasswordModal = action.payload;
    },
    setPlanDetails(state, action) {
      state.planLists = action.payload;
    },
    setIsPlanLoading(state, action) {
      state.isPlanLoading = action.payload;
    },
    setSelectPlanType(state, action) {
      state.selectPlanType = action.payload;
    },
    setSelectPlanList(state, action) {
      state.selectPlanList = action.payload;
    },
    setActivePlan(state, action) {
      state.activePlan = action.payload;
    },

    setUserCurrentTeam(state, action) {
      if (state.userDetails) {
        state.userDetails.currentTeamId = action.payload;
      }
    },

    setUserTeams(state, action) {
      if (state.userDetails) {
        state.userDetails.userTeams.push(action.payload);
      }
    },
  },
});

export const {
  actions: AccountReducerAction,
  name: AccountReducerName,
  reducer: AccountReducer,
} = account;
