import {
  AdContent,
  AdDescription,
  AdTitle,
  Container,
  ContentImage,
  SwipeAdImage,
} from "./style";
import AdImage from "../../../assets/images/landing-image.png";

const SwipeAdSection = () => {
  return (
    <Container>
      <AdContent>
        <AdTitle>Swipe Ads from Meta - FB & Instagram</AdTitle>
        <AdDescription>Access you Saved Ads from Extension Here</AdDescription>
      </AdContent>
      <SwipeAdImage>
        <ContentImage src={AdImage} alt="Ad image" />
      </SwipeAdImage>
    </Container>
  );
};

export default SwipeAdSection;
