import organizationImage from "../../../assets/images/organization.png";
import {
  Container,
  Organization,
  Title,
  SubTitle,
  ContentImage
} from "./style";
import { Box } from "@mui/material";
const OrganizationSection = () => {
  return (
    <Container>
      <Organization>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Title>
            Organisation at its best
          </Title>
          <SubTitle>
            Lorem ipsum dolor sit amet consectetur. Id erat libero magna eu enim 
            accumsan risus sagittis habitant. 
          </SubTitle>
        </Box>
        <ContentImage src={organizationImage} alt="organization" />
      </Organization>
    </Container>
  );
};

export default OrganizationSection;
