import Slider, { CustomArrowProps } from "react-slick";
import FavouriteBrandContainer from "./favouriteBrandContainer";
import TopBrandCard from "../commonComponents/brandCards/topBrandCard";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { favouriteBrandsSelector } from "../../store/metadata/selector";
import { brandItem } from "../../store/metadata/types";
import { DiscoverySagaActions } from "../../store/discovery/sagas";
import { useTheme } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { TopBrandsListWrapper } from "../../pageComponents/style";

const NavigateButtonStyle = {
  fill: "#58595B",
  background: "white",
  border: "1px solid #EAECEE",
  borderRadius: "50%",
  padding: "0.25rem",
  margin: "0.25rem",
};

const NextArrow = ({ className, onClick }: CustomArrowProps) => {
  return (
    <NavigateNextIcon
      onClick={onClick}
      className={className}
      sx={NavigateButtonStyle}
    />
  );
};

const PrevArrow = ({ className, onClick }: CustomArrowProps) => {
  return (
    <NavigateBeforeIcon
      onClick={onClick}
      className={className}
      sx={NavigateButtonStyle}
    />
  );
};

const FavouriteBrandDiscovery = () => {
  const favouriteBrands = useSelector(favouriteBrandsSelector);
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleViewAd = (brandId?: string) => {
    dispatch(DiscoverySagaActions.fetchBrandDiscoveryItems({ brandId }));
  };

  const slidesToShow = Math.min(favouriteBrands?.length, 6);
  const settings = {
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: Math.min(favouriteBrands?.length, 5),
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: Math.min(favouriteBrands?.length, 4),
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(favouriteBrands?.length, 3),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(favouriteBrands?.length, 2),
        },
      },
    ],
  };
  return (
    <FavouriteBrandContainer defaultExpanded={favouriteBrands.length > 0}>
      {favouriteBrands.length === 0 ? (
        <Box
          margin={"auto"}
          color={theme.colors.black2}
          fontWeight={500}
          fontSize={"0.938rem"}
        >
          No Favourite Brands
        </Box>
      ) : (
        <TopBrandsListWrapper style={{ width: "100%"}}>
          <Slider {...settings}>
            {favouriteBrands
              .slice(0, 5)
              .map((brand: brandItem, index: number) => (
                <TopBrandCard
                  buttonsToShow={["viewAdsButton"]}
                  isDiscovery
                  index={`0${index + 1}`}
                  item={brand}
                  // disableFavourite={true} // Future purpose
                  handleViewAd={handleViewAd}
                />
              ))
            }
          </Slider>
        </TopBrandsListWrapper>
      )}
    </FavouriteBrandContainer>
  );
};

export default FavouriteBrandDiscovery;
