import styled from "styled-components";
import tranScriptBg from "../../../assets/images/transcript-bg.png";
export const Container = styled.div`
  width: 100%;
  height: 22.857rem;
  margin: 7rem auto;
  `;

export const TranScriptContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 2.143rem;
  background-image: url(${tranScriptBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex-shrink: 0;
  padding: 0 4.143rem;

  @media ( max-width: 349px){
      padding: 0 1.143rem;
  }
`;

export const TranScriptData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5.357rem 0 3.571rem 0;
  width: 60%;
  gap: 1.429rem;

  @media ( max-width: 697px){
      width: 80%;
  }

  @media ( max-width: 405px){
      width: 100%;
  }
`;

export const TranScriptTitle = styled.h3`
  color: ${({ theme }) => `${theme.colors.white}`};
  font-size: 2.857rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media ( max-width: 1354px){
    font-size: 2.357rem;
  }

  @media ( max-width: 1200px){
    font-size: 1.857rem;
  }
`;

export const TranScriptLists = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.071rem;
`;

export const ListItemStyled = styled.div`
  display: flex;
  gap: 0.714rem;
  align-items: start;
  justify-content: start;
`;

export const ItemText = styled.h4`
  color: ${({ theme }) => `${theme.colors.white}`};
  font-size: 1.143rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.286rem;
  letter-spacing: 0.036rem;

  @media ( max-width: 1354px){
    font-size: 1.043rem;
  }

  @media ( max-width: 1200px){
    font-size: 0.943rem;
  }
`;

export const ItemIcon = styled.div`
  padding-top: 0.4rem;
`;

export const TranScriptContentImage = styled.div`
  height: 100%;
  width: 29.571rem;
  position: relative;
  top: -3.5rem;
`;

export const TranScriptImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
