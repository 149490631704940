import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 1.571rem 0 0;
  border-top: 1px solid ${({ theme }) => theme.colors.lightWhite2};
`;

export const FooterNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2.571rem 0;
`;

export const BrandLogo = styled.img`
  display: flex !important;
  justify-content: start !important;
  width: 16.571rem;
  height: 2.286rem;
  @media only screen and (max-width: 1440px) {
    width: 12rem;
    height: 1.657rem;
  }
`;

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  gap: 2.857rem;
  margin: 0 0.714rem;
`;

export const NavMenuSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavMenuItem = styled(NavLink)<{
  to?: string;
}>`
  && {
    display: flex;
    align-items: center;
    font-size: 1.143rem;
    font-weight: 400;
    line-height: 1.393rem;
    letter-spacing: 0.036rem;
    color: ${({ theme }) => `${theme.colors.white}`};
    text-decoration: none;
    padding: 0.357rem 2.143rem;
    &:not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.colors.lightWhite2};
    }
    &:first-child {
      padding-left: 5px;
    }
    &:last-child {
      padding-right: 5px;
    }
  }
`;

export const LeftNavigation = styled(NavLink)<{ to?: string }>`
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0.571rem 0.714rem 0.857rem;
  text-decoration: none;
`;

export const LeftNavMenuSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.071rem;
`;

export const LeftNavMenuItem = styled(NavLink)<{
  to?: string;
}>`
  && {
    display: flex;
    align-items: center;
    font-size: 1.143rem;
    font-weight: 400;
    line-height: 1.393rem;
    letter-spacing: 0.036rem;
    color: ${({ theme }) => `${theme.colors.white}`};
    text-decoration: none;
    gap: 0.429rem;
    opacity: 0.5;
  }
`;
