import {
  CircularProgress,
  DialogTitle,
  FormControl,
  InputAdornment,
  Stack,
} from "@mui/material";
import {
  CloseIconWrapper,
  CreateBoardWrapper,
  FontGrayNormal,
  ModalIconWrapper,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
  StyledFormControl,
  StyledInputLabel,
  StyledSelectField,
  StyledTextField,
} from "./style";
import {
  ArrowDownIcon,
  CloseIcon,
  FolderOpenIcon,
  ImageGalleryIcon,
} from "../../assets/Icons";
import { CreateBoardValueProps } from "../../utils/propTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import {
  folderListsSelector,
  openBoardModalSelector,
} from "../../store/metadata/selector";
import { folderItem } from "../../store/metadata/types";
import { useForm } from "react-hook-form";
import { createBoardValidationSchema } from "../../utils/formValidationSchema";
import { ErrorMessage } from "../../pageComponents/auth/style";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { StyledDivider, StyledMenuItem } from "../../globalStyle";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { useState } from "react";
import { webkitInputCSSFix } from "../../utils/helpers";
import { useParams } from "react-router-dom";

const CreateBoardModal = () => {
  const folderLists = useSelector(folderListsSelector);
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<CreateBoardValueProps>({
    resolver: yupResolver(createBoardValidationSchema),
  });

  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const openBoardModal = useSelector(openBoardModalSelector);

  const handleLoadingState = () => {
    setLoading(false);
    reset();
  };

  const onSubmit = () => {
    const values = getValues();
    setLoading(true);
    dispatch(
      MetaDataSagaActions.createBoard({ values: values, handleLoadingState })
    );
  };

  const handleClose = () => {
    reset();
    dispatch(MetaDataReducerAction.setOpenBoardModal(false));
  };
  const { folderId } = useParams();

  return (
    <StyledDialog
      onClose={handleClose}
      open={openBoardModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ padding: "1.875rem 1.875rem 1.25rem 1.875rem" }}>
        <Stack direction="row" gap="0.75rem" alignItems="start">
          <ModalIconWrapper>
            <ImageGalleryIcon />
          </ModalIconWrapper>
          <Stack>
            <ModalTitle>Create Board</ModalTitle>
            <FontGrayNormal>Folders keep your boards organized</FontGrayNormal>
          </Stack>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <StyledDivider />
      <CreateBoardWrapper>
        <StyledFormControl fullWidth>
          <StyledInputLabel htmlFor="name">Board Name</StyledInputLabel>
          <StyledTextField
            id="name"
            variant="outlined"
            sx={webkitInputCSSFix}
            {...register("name", { required: true })}
          />
          {errors.name?.message && (
            <ErrorMessage>{errors.name?.message}</ErrorMessage>
          )}
        </StyledFormControl>

        <StyledFormControl fullWidth>
          <StyledInputLabel htmlFor="description">
            Description (Optional)
          </StyledInputLabel>
          <StyledTextField
            id="description"
            variant="outlined"
            sx={webkitInputCSSFix}
            required
            {...register("description")}
          />
        </StyledFormControl>

        <FormControl
          fullWidth
          sx={{
            "& > label": {
              top: "-1.375rem",
              left: "-0.938rem",
            },
          }}
        >
          <StyledInputLabel
            htmlFor="folderId"
            sx={{ fontSize: "1.5rem !important" }}
          >
            Location
          </StyledInputLabel>
          <StyledSelectField
            id="folderId"
            variant="outlined"
            defaultValue={folderId ?? folderLists?.teamFolders[0]?._id}
            startAdornment={
              <InputAdornment position="start">
                <FolderOpenIcon />
              </InputAdornment>
            }
            IconComponent={(props) => (
              <ArrowDownIcon className={props.className} />
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: 15,
                  maxHeight: "200px",
                  overflowY: "auto",
                },
              },
            }}
            {...register("folderId")}
          >
            {folderLists?.teamFolders?.map((item: folderItem) => (
              <StyledMenuItem key={item._id} value={item._id}>
                {item.name}
              </StyledMenuItem>
            ))}
          </StyledSelectField>
        </FormControl>
      </CreateBoardWrapper>
      <StyledDivider />
      <StyledContainedButton
        variant="contained"
        size="small"
        sx={{
          margin: "1.875rem 1.875rem 1.875rem auto",
          padding: "0.313rem 2.375rem",
        }}
        onClick={handleSubmit(onSubmit)}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
      >
        Create
      </StyledContainedButton>
    </StyledDialog>
  );
};

export default CreateBoardModal;
