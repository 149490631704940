import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Grid } from "@mui/material";
export const Container = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  margin: 5.714rem 0;
  gap: 2rem;

  @media ( max-width: 786px ){
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ImageBox = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 20px;
  width: 45%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ( max-width: 786px ){
    width: 100%;
  }
`;

export const ImageSection = styled(Grid)`
  margin-right: 12px;
  display: flex;
  height: 45.75rem;
  width: 100%;
`;

export const AboutBox = styled.div`
  width: 55%;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 20px;
  padding: 20px;
  @media ( max-width: 786px ){
    width: 100%;
  }
`;

export const Title = styled.div`
  font-size: 35px;
  font-weight: 600;
  line-height: 48.76px;
  text-align: left;
  color: ${({ theme }) => `${theme.colors.white}`};
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 20px;
  color: ${({ theme }) => `${theme.colors.white}`};
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  padding: 1.071rem 1.429rem;
  align-items: start;
  gap: 1.429rem;
  border-radius: 1.071rem;
  border: ${({ theme }) => `1px solid ${theme.colors.lightWhite}`};
  background: ${({ theme }) => theme.colors.white2};
  flex-grow: 1;
`;

export const TabIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.286rem;
  height: 3.286rem;
  flex-shrink: 0;
  border-radius: 1.643rem;
  color: ${({ theme }) => `${theme.colors.white}`};
  border: 1px solid ${({ theme }) => theme.colors.lightWhite2};
  background: ${({ theme }) => theme.colors.lightWhite2};
`;

export const TextDescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const TabText = styled.div`
  font-size: 1.143rem;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.023rem;
  color: ${({ theme }) => `${theme.colors.white}`};
`;

export const TabDescription = styled.div`
  font-size: 10px;
  font-weight: 300;
  line-height: 14.63px;
  text-align: left;
  color: ${({ theme }) => `${theme.colors.white}`};
`;