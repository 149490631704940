import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.discoveryState || initialState;

export const discoveryItemsSelector = createSelector(
  selectDomain,
  (state) => state.discoveryItems
);
export const communityItemsSelector = createSelector(
  selectDomain,
  (state) => state.communityItems
);
export const discoveryViewTypeSelector = createSelector(
  selectDomain,
  (state) => state.viewType
);
export const isLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);
export const discoverySelectedSortOptionSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.sortValue
);

export const filterOptionSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions
);

export const discoverySelectedBrandsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedPageIds
);

export const discoverySelectedPlatformsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedPlatformIds
);

export const discoveryTabIndexSelector = createSelector(
  selectDomain,
  (state) => state.discoveryTabIndex
);

export const discoveryBrandsSelector = createSelector(
  selectDomain,
  (state) => state.discoveryTopBrandLists
);

export const discoveryPageSearchSelector = createSelector(
  selectDomain,
  (state) => state.search
);
export const discoveryBrandsParamsSelector = createSelector(
  selectDomain,
  (state) => state.discoveryBrandsParams
);

export const discoveryFavouriteBrandFilterSelector = createSelector(
  selectDomain,
  (state) => state.discoveryFavouriteBrandFilterEnabled
);

export const discoveryBrandLoadingSelector = createSelector(
  selectDomain,
  (state) => state.discoveryBrandsLoading
);

export const discoveryFilterCountsSelector = createSelector(
  selectDomain,
  (state) => {
    const { selectedPlatformIds, selectedPageIds, selectedBoardIds } = state.filterOptions;
    return (
      selectedPlatformIds.length +
      selectedPageIds.length +
      selectedBoardIds.length
    );
  }
);

export const dashboardExploreLoungeAdsSelector = createSelector(
  selectDomain,
  (state) => state.discoveryItems?.data?.slice(0, 10)
);
