import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { useTheme } from "styled-components";
import { CheckedIcon, UncheckedIcon } from "../../../assets/Icons";

const CheckBoxGroup = ({
  list,
  selected,
  onChangeHandler,
  staticIcon,
}: {
  list: any;
  onChangeHandler: any;
  selected: any;
  staticIcon?: any;
}) => {
  const theme = useTheme();

  // Create a shallow copy of the list to avoid mutating the original array
  const sortedList = [...list].sort((a: any, b: any) => {
    const isASelected: any = selected?.indexOf(a._id) !== -1;
    const isBSelected: any = selected?.indexOf(b._id) !== -1;
    return isBSelected - isASelected; // Moves selected items to the top
  });
  
  return (
    <>
      {sortedList?.map((item: any) => {
        const isItemSelected = selected?.indexOf(item._id) !== -1;
        return (
          <FormControlLabel
            key={item._id}
            control={
              <Checkbox
                checked={isItemSelected}
                onChange={(event) => onChangeHandler(event, item._id)}
                icon={<UncheckedIcon />}
                checkedIcon={<CheckedIcon />}
                sx={{ padding: "0.563rem" }}
              />
            }
            label={
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  gap: "0.625rem",
                }}
              >
                {typeof item.logo === "string" ? (
                  <img
                    src={item.logo}
                    alt={item.name}
                    style={{
                      width: "1.25rem",
                      height: "1.25rem",
                    }}
                  />
                ) : item.logo ? (
                  <item.logo />
                ) : (
                  staticIcon
                )}
                <Typography
                  fontSize={"1rem"}
                  fontWeight={500}
                  color={theme.colors.neutral80}
                >
                  {item.name}
                </Typography>
              </Stack>
            }
            sx={{ gap: "0.375rem" }}
          />
        );
      })}
    </>
  );
};

export default CheckBoxGroup;
