import withoutLounge from "../../../assets/images/withoutLoungeAd.png";
import withLounge from "../../../assets/images/withLoungeAd.png";
import {
  Container,
  ContentImage,
  HeroImage,
  LoungeTitle
} from "./style";
const LoungeSection = () => {
  return (
    <Container>
      <HeroImage>
        <LoungeTitle>
          Without Swipe Lounge...
        </LoungeTitle>
        <ContentImage src={withoutLounge} alt="withoutSwipeLounge" />
      </HeroImage>
      <HeroImage>
        <LoungeTitle>
          With Swipe Lounge...
        </LoungeTitle>
        <ContentImage src={withLounge} alt="withSwipeLounge" />
      </HeroImage>
    </Container>
  );
};

export default LoungeSection;
