import { NavLink } from "react-router-dom";
import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin: 4.286rem auto;
  gap: 4.571rem;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 40%;
  gap: 2.5rem;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.429rem;
`;
export const ContentTitle = styled.div`
  font-size: 3.429rem;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: ${({ theme }) => `${theme.colors.white}`};
`;

export const ContentDescription = styled.p`
  font-size: 1.143rem;
  font-weight: 400;
  font-style: normal;
  line-height: 2.286rem;
  letter-spacing: 0.036rem;
  color: ${({ theme }) => `${theme.colors.white}`};
`;

export const CtaButton = styled(NavLink)<{ to?: string }>`
  && {
    border-radius: 7.143rem;
    font-size: 1.143rem;
    color: ${({ theme }) => theme.colors.white};
    line-height: normal;
    background: ${({ theme }) => theme.colors.backgroundSecondary};
    padding: 0.929rem 1.429rem;
    border: none;
    outline: none;
    text-decoration: none;
  }
`;

export const ExploreLoungeImage = styled.div`
  width: 60%;
`;

export const ContentImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 7px solid ${({ theme }) => theme.colors.lightWhite2};
  box-shadow: 0px 16px 40px 0px ${({ theme }) => theme.colors.darkBlue};
  box-sizing: border-box;
`;

export const Tab = styled.div`
  display: flex;
  /* width: 100%; */
  padding: 1.071rem 1.429rem;
  align-items: center;
  gap: 1.429rem;
  border-radius: 1.071rem;
  border: ${({ theme }) => `1px solid ${theme.colors.lightWhite}`};
  background: ${({ theme }) => theme.colors.white2};
`;

export const TabIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.286rem;
  height: 3.286rem;
  flex-shrink: 0;
  border-radius: 1.643rem;
  color: ${({ theme }) => `${theme.colors.white}`};
  border: 1px solid ${({ theme }) => theme.colors.lightWhite2};
  background: ${({ theme }) => theme.colors.lightWhite2};
`;

export const TabText = styled.div`
  font-size: 1.143rem;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.023rem;
  color: ${({ theme }) => `${theme.colors.white}`};
`;