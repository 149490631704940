import { Tab, Tabs } from "@mui/material";

import { TabContainer } from "./style";
import { useTheme } from "styled-components";
import { TabsHeaderProps } from "../../utils/propTypes";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { CustomTooltip } from "../commonComponents/customTooltip";

const TabsHeader = ({ tabIndex, setTabIndex, data }: TabsHeaderProps) => {
  const theme = useTheme();
  const location = useLocation();
  const handleTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  useEffect(() => {
    data.map((item, index) => {
      location.hash
        ? item.id === location.hash.slice(1) && setTabIndex(index)
        : (window.location.hash = data[0].id);
    });
  }, []);

  const getTooltipTitle = (id: string) => {
    switch (id) {
      case "discovery":
        return "All Ads in the Platform";
      case "community":
        return "Your Team's Ads";
      default:
        return "";
    }
  };

  return (
    <TabContainer>
      <Tabs
        value={tabIndex}
        onChange={handleTabs}
        sx={{ ".MuiTabs-flexContainer": { gap: "5rem" }, minHeight: "3rem" }}
      >
        {data.map(({ id, label }) => (
          <CustomTooltip
             key={id} 
             title={getTooltipTitle(id)} 
             >
            <Tab
              key={id}
              label={label}
              to={`/${location.pathname.slice(1)}#${id}`}
              component={NavLink}
              sx={{
                "&.Mui-selected": {
                  color: theme.colors.blue,
                  borderBottom: "1px solid",
                  fontWeight: "600",
                },
                color: theme.colors.gray1,
                textTransform: "capitalize",
                paddingBottom: "1rem",
                minWidth: "fit-content",
                fontSize: "1rem",
                minHeight: "3rem",
              }}
            />
          </CustomTooltip>
        ))}
      </Tabs>
    </TabContainer>
  );
};

export default TabsHeader;
