import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DiscoveryItem,
  DiscoveryStateTypes,
  FilterOptions,
  SetFilterOptionsPayload,
  ViewTypeEnum,
} from "./types";
import { listType } from "../metadata/types";

export const initialState: DiscoveryStateTypes = {
  discoveryItems: {
    data: [],
    total: 0,
  },
  communityItems: {
    data: [],
    total: 0,
  },
  viewType: ViewTypeEnum.Card,
  isLoading: false,
  filterOptions: {
    selectedPlatformIds: [],
    selectedPageIds: [],
    selectedBoardIds: [],
    limit: 30,
    skip: 0,
    sortValue: "Newest",
    sort: {
      createdAt: -1,
    },
    selectedTagIds: [],
  },
  discoveryTabIndex: 0,
  discoveryTopBrandLists: {
    top10: [],
    data: [],
    total: 0,
  },
  discoveryBrandsParams: {
    skip: 0,
    limit: 10,
    favourite: false,
  },
  search: "",
  discoveryFavouriteBrandFilterEnabled: false,
  discoveryBrandsLoading: false,
};

const discovery = createSlice({
  name: "discoveryState",
  initialState,
  reducers: {
    setDiscoveryItems(state, action) {
      state.discoveryItems.total = action.payload.total;
      state.discoveryItems.data = [
        ...state.discoveryItems.data,
        ...action.payload.data,
      ];
    },
    setCommunityItems(state, action) {
      state.communityItems.total = action.payload.total;
      state.communityItems.data = [
        ...state.communityItems.data,
        ...action.payload.data,
      ];
    },
    setViewType(state, action) {
      state.viewType = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSelectedSortOption(state, action) {
      state.filterOptions.sortValue = action.payload.sortValue;
      state.filterOptions.sort = action.payload.sortObject;
    },
    setSelectedFilterOptions<T extends keyof FilterOptions>(
      state: DiscoveryStateTypes,
      action: PayloadAction<SetFilterOptionsPayload<T>>
    ) {
      const { selectAll, checked, id, list, stateName } = action.payload;
      const { filterOptions } = state;
      const selectedFilters = filterOptions[stateName] as string[];
      if (selectAll) {
        if (checked) {
          const mergedArray = [
            ...selectedFilters,
            ...list.map((item: listType) => item._id),
          ];
          const uniqueArray = mergedArray.filter(
            (value, index) => mergedArray.indexOf(value) === index
          );
          (filterOptions[stateName] as string[]) = uniqueArray;
        } else {
          (filterOptions[stateName] as string[]) = [];
        }
      } else {
        const index = (filterOptions[stateName] as string[]).indexOf(id);
        if (checked && index === -1) {
          (filterOptions[stateName] as string[]).push(id);
        } else if (!checked && index !== -1) {
          (filterOptions[stateName] as string[]).splice(index, 1);
        }
      }
    },
    setDiscoveryTabIndex(state, action) {
      state.discoveryTabIndex = action.payload;
    },
    setDiscoveryTopBrands(state, action) {
      const { brandData, skipValue } = action.payload;
      state.discoveryTopBrandLists.top10 = brandData?.top10 ?? [];
      state.discoveryTopBrandLists.total = brandData.total;
      state.discoveryTopBrandLists.data =
        skipValue === 0
          ? brandData.data
          : [...state.discoveryTopBrandLists.data, ...brandData.data];
    },
    setDiscoveryBrandsParams(state, action) {
      state.discoveryBrandsParams = {
        ...state.discoveryBrandsParams,
        skip: action.payload,
      };
    },
    setFilterOptionBrandId(state, action) {
      state.filterOptions.selectedPageIds = [action.payload];
    },
    setDiscoveryPageSearchInputValue(state, action) {
      state.search = action.payload;
    },
    setFilterOptionSkipValue(state, action) {
      state.filterOptions = {
        ...state.filterOptions,
        skip: action.payload,
      };
    },
    setDiscoveryItemToInitialSet(state, action) {
      state.discoveryItems.data = [];
      state.discoveryItems.total = 0;
    },
    setDiscoveryTopBrandsToInitialState(state, action) {
      state.discoveryTopBrandLists.top10 = [];
      state.discoveryTopBrandLists.total = 0;
      state.discoveryTopBrandLists.data = [];
    },
    setDiscoveryFavouriteBrandFilter(state, action) {
      state.discoveryFavouriteBrandFilterEnabled = action.payload;
    },
    resetDiscoveryState(state) {
      //Swipe file ads listing reset state
      state.discoveryItems.data = [];
      state.discoveryItems.total = 0;
      state.communityItems.data = [];
      state.communityItems.total = 0;
      state.filterOptions.selectedPlatformIds = [];
      state.filterOptions.selectedPageIds = [];
      state.filterOptions.selectedBoardIds = [];
      state.filterOptions.limit = 30;
      state.filterOptions.skip = 0;
      state.filterOptions.sortValue = "Newest";
      state.filterOptions.sort = {
        createdAt: -1,
      };
      state.filterOptions.selectedTagIds = [];
      state.discoveryFavouriteBrandFilterEnabled = false;
    },
    setBrandsLoading(state, action) {
      state.discoveryBrandsLoading = action.payload;
    },
    resetFilters(state) {
      state.filterOptions = initialState.filterOptions;
    },
    setSelectedTagIds(state, action) {
      state.filterOptions.selectedTagIds = action.payload;
    },
    setUpdatedBoardIds(state, action) {
      const updateDiscoveryData = state.discoveryItems.data.map(
        (item: DiscoveryItem) => {
          if (item._id === action.payload.adId) {
            return {
              ...item,
              board: action.payload.updatedBoardList,
            };
          } else {
            return item;
          }
        }
      );
      state.discoveryItems.data = updateDiscoveryData;
    },
    setDiscoveryItemData(state, action) {
      state.discoveryItems.data = action.payload;
    },
    setSelectedBoardId(state, action) {
      state.filterOptions.selectedBoardIds = [action.payload];
    },
  },
});

export const {
  actions: DiscoveryReducerAction,
  name: DiscoveryReducerName,
  reducer: DiscoveryReducer,
} = discovery;
