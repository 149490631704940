import { Container } from './style'
import HeroSection from '../../components/landingPage/heroSection'
import LandingNavbar from '../../components/landingPage/navbar'
import SwipeAdSection from '../../components/landingPage/swipeAdSection'
import SocialMediaSection from '../../components/landingPage/socialMediaSection'
import ExploreLoungeSection from '../../components/landingPage/exploreLoungeSection'
import TranScriptSection from '../../components/landingPage/tranScriptSection'
import Footer from '../../components/landingPage/Footer'
import LoungeSection from '../../components/landingPage/loungeSection'
import AboutPlatform from '../../components/landingPage/aboutPlatform'
import OrganizationSection from '../../components/landingPage/organizationSection'

const LandingPage = () => {
  return (
    <Container>
      <LandingNavbar/>
      <HeroSection/>
      <LoungeSection />
      <AboutPlatform />
      <SwipeAdSection/>
      <SocialMediaSection/>
      <OrganizationSection />
      <ExploreLoungeSection/>
      <TranScriptSection/>
      <Footer/>
    </Container>
  ) 
}

export default LandingPage