import { Box, Divider } from "@mui/material";
import { OpenFolder } from "../../assets/Icons";
import { FolderListItem } from "../../pageComponents/auth/style";
import useConfig from "../../hooks/useConfig";
import { folderItem } from "../../store/metadata/types";
import { useDispatch, useSelector } from "react-redux";
import { sharedFolderListSelector } from "../../store/metadata/selector";
import routeConstant from "../../utils/routeConstant";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MetaDataReducerAction } from "../../store/metadata/slice";

const SidebarSharedFolders = () => {
  const { drawerOpen } = useConfig();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sharedFolder = useSelector(sharedFolderListSelector);

  const constants = {
    sharedFolders: "Shared Folders",
  };

  const selectedFolderId = pathname.split("/")[2];
  const selectedFolderName = pathname.split("/")[1];
  const isSelectedInCurrentFolder =
    selectedFolderName === Object.keys(constants)[0];

  /* const handleFolderClick = (item: folderItem) => {
    dispatch(MetaDataReducerAction.setSelectedSharedFolder(item));
  }; */

  const handleNavigate = (id: string) => {
    dispatch(MetaDataReducerAction.resetSwipefileState());
    dispatch(MetaDataReducerAction.setSelectedSharedFolderId(id));
    dispatch(MetaDataReducerAction.setSelectedTabIndex(0));
    navigate(`${routeConstant.sharedFolders}/${id}`);
  };

  return (
    <>
      <Divider sx={{ borderColor: "#2f4757" }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "1.5rem 1.5rem 1.8rem 1.8rem",
        }}
      >
        <span>{constants.sharedFolders}</span>
      </div>

      <div style={{ marginLeft: "1rem" }}>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "17vh",
            "::-webkit-scrollbar": {
              width: "4px",
            },
            marginTop: "-1rem",
          }}
        >
          {sharedFolder?.userSharedFolders?.length > 0 &&
            sharedFolder?.userSharedFolders?.map((item: folderItem) => (
              <div>
                <FolderListItem
                  key={item._id}
                  style={{
                    borderRadius: "4px",
                    background:
                      selectedFolderId === item._id && isSelectedInCurrentFolder
                        ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                        : "transparent",
                  }}
                >
                  <OpenFolder />
                  {drawerOpen && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => handleNavigate(item._id)}
                        style={{
                          marginLeft: 10,
                          cursor: "pointer",
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        {item.name}
                      </div>
                    </div>
                  )}
                </FolderListItem>
              </div>
            ))}
        </Box>
      </div>
    </>
  );
};

export default SidebarSharedFolders;
