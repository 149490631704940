import { call, put, select } from "redux-saga/effects";
import { createSliceSaga } from "../saga-helper";
import {
  AddOrRemoveTag,
  CreateTagService,
  SaveAdTagsService,
} from "../../services/TagsService";
import { AxiosResponse } from "axios";
import { TagsReducerAction } from "./slice";
import { selectNewTageValueSelector } from "./selector";
import { ToastTypes, notify } from "../../utils/helpers";
import { MetaDataReducerAction } from "../metadata/slice";
import { SwipeFileReducerAction } from "../swipeFile/slice";
import { adDetailsType, tagItem } from "../metadata/types";
import { TagsListsSelector, adDetailsSelector } from "../metadata/selector";
import * as Sentry from "@sentry/react";

const saga = createSliceSaga({
  name: "tags-state",
  caseSagas: {
    *createTag(action: { payload: string; type: string }) {
      try {
        const tagValue: string = yield select(selectNewTageValueSelector);
        const regex = /^(?! )[a-zA-Z0-9 ]*$/;
        if (regex.test(tagValue)) {
          const response: AxiosResponse = yield call(CreateTagService, {
            name: tagValue,
          });
          if (response?.status === 200) {
            const tagId = response?.data?.data?._id;
            const tagName = response?.data?.data?.name;
            const assignNewTagWithAd = {
              saveTags: [tagId],
              adId: action.payload,
              removeTags: [],
            };

            const addTagToTagList = response?.data?.data;

            yield put(
              MetaDataReducerAction.setNewTagToTagList(addTagToTagList)
            );

            const assignTagResponse: AxiosResponse = yield call(
              SaveAdTagsService,
              assignNewTagWithAd
            );

            if (assignTagResponse.status === 200) {
              const addTagInAdItem = {
                _id: tagId,
                name: tagName
              };
              let adDetailsData: adDetailsType = yield select(
                adDetailsSelector
              );
              adDetailsData = {
                ...adDetailsData,
                Tags: [...adDetailsData.Tags, addTagInAdItem],
              };
              yield put(MetaDataReducerAction.setAdDetails(adDetailsData));
            }
            notify({
              message: response?.data?.message,
              type: ToastTypes.SUCCESS,
            });
            yield put(TagsReducerAction.setTagItems());
          }
        } else {
          notify({
            message: "No special characters are allowed!",
            type: ToastTypes.ERROR,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    //Calling from Ad Details Drawer Component
    *addOrRemoveTagsFromAd(action: {
      payload: {
        tagId: string;
        adId: string;
      };
      type: string;
    }) {
      try {
        const response: AxiosResponse = yield call(
          AddOrRemoveTag,
          action.payload
        );
        if (response?.status === 200) {
          const updatedTagItems = response?.data?.data[0]?.Tags;

          let adDetailsData: adDetailsType = yield select(adDetailsSelector);
          adDetailsData = {
            ...adDetailsData,
            Tags: updatedTagItems,
          };
          yield put(MetaDataReducerAction.setAdDetails(adDetailsData));
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    //Calling from AdTable Component
    *saveAdTags(action: {
      payload: {
        adId: string;
        saveTags: string[];
        removeTags: string[];
        reFetchData: Function;
      };
      type: string;
    }) {
      try {
        const { adId, saveTags, removeTags, reFetchData } = action.payload;

        yield put(MetaDataReducerAction.setIsLoading(true));

        const reqBody = {
          adId,
          saveTags,
          removeTags,
        };
        const response: AxiosResponse = yield call(SaveAdTagsService, reqBody);

        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
          const tagItems: tagItem[] = yield select(TagsListsSelector);
          const updatedTagList = tagItems.filter((item) => {
            if (saveTags.includes(item._id)) {
              return { _id: item._id, name: item.name };
            }
          });
          let adDetailsData: adDetailsType = yield select(adDetailsSelector);
          yield put(
            MetaDataReducerAction.setAdDetails({
              ...adDetailsData,
              Tags: saveTags,
            })
          );
          yield put(
            SwipeFileReducerAction.setUpdatedTagIds({
              adId: adId,
              updatedTagList: updatedTagList,
            })
          );
          yield put(MetaDataReducerAction.setSelectedSaveAdId(""));
          yield put(MetaDataReducerAction.setIsLoading(false));
          yield put(TagsReducerAction.setOpenTagsDropdown("false"));
          yield put(MetaDataReducerAction.setSaveAdPopupAnchorEl(null));
          reFetchData && reFetchData();
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: TagsSagaActions,
  saga: TagsSaga,
  name: TagsSagaName,
} = saga;
