import {
  Container,
  AboutBox,
  Title,
  Description,
  Tab,
  TabIcon,
  TextDescriptionBox,
  TabText,
  TabDescription,
  ImageBox,
  ImageSection
} from "./style";
import { useContext } from "react";
import { CarouselContext } from "../../../context/CarouselContext";
import { CarouselType } from "../../../types/Carousel";
import { Grid } from "@mui/material";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

const tabData = [
  { icon: <BookmarkBorderOutlinedIcon />, text: "Save Ads", description: "Manage the Platform from here" },
  { icon: <FileUploadOutlinedIcon />, text: "Upload Ads", description: "Manage the Platform from here" },
  { icon: <CategoryOutlinedIcon />, text: "Brands", description: "Manage the Platform from here" },
  { icon: <LocalOfferOutlinedIcon />, text: "Custom Tags", description: "Manage the Platform from here" },
  { icon: <MessageOutlinedIcon />,text: "Comments", description: "Manage the Platform from here"},
  { icon: <SearchOutlinedIcon />, text: "Advanced Search", description: "Manage the Platform from here" },
  { icon: <NotificationsOutlinedIcon />, text: "Get Notified ", description: "Manage the Platform from here" },
  { icon: <PeopleOutlinedIcon />, text: "Team Collaboration", description: "Manage the Platform from here" },
  { icon: <NoteAddOutlinedIcon />, text: "Generate Transcripts", description: "Manage the Platform from here" },
];

const AboutPlatform = () => {
  const { carouselImages } = useContext(CarouselContext);

  const renderCarousel = (images: CarouselType[], direction: "ltr" | "rtl") => (
    <div className={`Marquee-content Marquee-content-${direction}`}>
      {[...Array(6)].flatMap((_, repetitionIndex) =>
        images.map((image, index) => (
          <div className="Marquee-tag" key={`${index}-${repetitionIndex}`}>
            <img src={image.imageUrl} alt="Vertical-Card-1" />
          </div>
        ))
      )}
    </div>
  );

  return (
    <Container>
      <ImageBox>
        <ImageSection xs={12} md={6}>
          {carouselImages.carousel_one?.length > 0 && (
            <>
              <div className="Marquee">
                {renderCarousel(carouselImages.carousel_one, "ltr")}
              </div>
              <div className="Marquee">
                {renderCarousel(carouselImages.carousel_two, "rtl")}
              </div>
              <div className="Marquee">
                {renderCarousel(carouselImages.carousel_three, "ltr")}
              </div>
            </>
          )}
        </ImageSection>
      </ImageBox>
      <AboutBox>
        <Title>
          About Platform
        </Title>
        <Description>
          Lorem ipsum dolor sit amet consectetur. Venenatis sit aliquam faucibus 
          netus ut sed condimentum imperdiet neque Fringilla eget varius 
        </Description>
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ display: 'flex' }}>
          {tabData.map(({ icon, text, description }, index) => (
            <Grid key={index} item xs={12} sm={6} md={6} lg={4} xl={4} style={{ display: 'flex' }}>
              <Tab>
                <TabIcon>{icon}</TabIcon>
                <TextDescriptionBox>
                  <TabText>{text}</TabText>
                  <TabDescription>{description}</TabDescription>
                </TextDescriptionBox>
              </Tab>
            </Grid>
          ))}
        </Grid>
      </AboutBox>
    </Container>
  );
};

export default AboutPlatform;
