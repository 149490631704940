import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - 480px);
  width: 100%;
  margin: 4.286rem auto;
  gap: 5rem;
  @media (max-width: 1800px) {
    max-width: calc(100% - 190px);
  }
  
  @media (max-width: 1200px) {
    max-width: calc(100% - 160px);
  }
  
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const AdContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.429rem;
`;
export const AdTitle = styled.h3`
  color: ${({ theme }) => `${theme.colors.white}`};
  text-align: center;
  font-size: 2.857rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
export const AdDescription = styled.p`
  color: ${({ theme }) => `${theme.colors.white}`};
  text-align: center;
  font-size: 1.143rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.036rem;
`;

export const SwipeAdImage = styled.div`
  width: 100%;
  height: 42.857rem;
  margin: 0 auto;
`;

export const ContentImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 7px solid ${({ theme }) => theme.colors.lightWhite2};
  box-shadow: 0px 16px 40px 0px ${({ theme }) => theme.colors.darkBlue};
  box-sizing: border-box;
`;
