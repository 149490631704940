import axiosClient from "../apiClient";
import { createFolderParamType } from "../types/FolderTypes";
import * as qs from "qs";

export const CreateFolderService = async (params: createFolderParamType) => {
  try {
    const response = await axiosClient.post("/folder", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const FolderAccessMembersService = async (
  folderId: string,
  search?: string
) => {
  try {
    const response = await axiosClient.get(
      `/folder/access-members/${folderId}?${search ? `search=${search}` : ``}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const FolderTeamMembersService = async (
  folderId: string,
  skip: number,
  limit: number,
  search?: string
) => {
  try {
    const response = await axiosClient.get(
      `/folder/team-members/${folderId}?${
        search ? `search=${search}` : ``
      }&skip=${skip}&limit=${limit}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const FolderInviteService = async (
  folderId: string,
  userEmail: string
) => {
  const requestBody = { userEmail: userEmail };
  try {
    const response = await axiosClient.post(
      `/folder/send-invite-for-folder/${folderId}`,
      requestBody
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const FolderTeamShareService = async (folderId: string) => {
  try {
    const response = await axiosClient.get(
      `/folder/shared-with-team/${folderId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const SharedFolderListService = async () => {
  try {
    const response = await axiosClient.get(`/folder/shared-folder/list`);
    return response;
  } catch (error) {
    return error;
  }
};

export const FetchFolderListService = async (params?: {
  favourite: boolean;
}) => {
  try {
    const filterObject = {
      ...(params && params.favourite && { favourite: params.favourite }),
    };
    const queryString = qs.stringify(filterObject);
    const apiUrl = queryString ? `/folder?${queryString}` : `/folder/team`;
    const response = await axiosClient.get(apiUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const UpdateFolder = async (folderId: string, name?: string) => {
  try {
    let response;
    let requestBody: {
      name?: string;
    } = {};
    if (name) {
      requestBody["name"] = name;
      response = await axiosClient.put(`/folder/${folderId}`, requestBody);
    } else {
      response = await axiosClient.put(`/folder/${folderId}`);
    }
    return response;
  } catch (error) {
    return error;
  }
};

export const DeleteFolder = async (folderId: string) => {
  try {
    let response = await axiosClient.delete(`/folder/${folderId}`);
    return response;
  } catch (error) {
    return error;
  }
};
