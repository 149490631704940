import LandingImage from "../../../assets/images/landing-image.png";
import {
  Container,
  HeroDescription,
  ContentImage,
  HeroImage,
  MainContent,
  HeroContent,
  HighlightedText,
  HeroTitle,
  CtaButton,
} from "./style";
const HeroSection = () => {
  return (
    <Container>
      <HeroContent>
        <MainContent>
          <HeroTitle>
            The Ultimate Platform for Marketing Agencies and{" "}
            <HighlightedText>AD WorkFlow</HighlightedText>
          </HeroTitle>
          <HeroDescription>
            A Platform for Ad Creators and Managers where you save ads, upload
            ads, collaborate in team and share ads, organize ads into boards and
            folders, add comments and tags to ads. Everything about Ads in one
            platform
          </HeroDescription>
        </MainContent>
        <CtaButton>Get Started</CtaButton>
      </HeroContent>
      <HeroImage>
        <ContentImage src={LandingImage} alt="Platform illustration" />
      </HeroImage>
    </Container>
  );
};

export default HeroSection;
