import {
  Button,
  IconButton,
  InputLabel,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { StyledMenuItem } from "../../globalStyle";

export const SettingsSectionWrapper1 = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 10px;
  padding: 2%;
  background: white;
  position: relative;
`;

export const SettingsSectionWrapper2 = styled.div`
  width: 70%;
  margin: auto;
`;

export const SettingsHeaderText = styled(Typography)`
  && {
    font-weight: 600;
    font-size: 1.375rem;
    color: ${({ theme }) => theme.colors.black2};
  }
`;

export const LogoutButton = styled(Button)`
  && {
    background-color: #fff0f2;
    color: ${({ theme }) => theme.colors.red200};
    text-transform: capitalize;
    border-radius: 50px;
    position: absolute;
    top: 1.875rem;
    right: 16.188rem;
    padding: 0.625rem 1.25rem;
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid #ffe3e7;
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  && {
    color: ${({ theme }) => theme.colors.black2} !important;
    font-size: 1.125rem;
    font-weight: 400;
  }
`;

export const StyledOutlinedInput = styled(OutlinedInput)`
  && {
    color: ${({ theme }) => theme.colors.black2};
    .MuiInputBase-input {
      padding: 1.031rem 0.875rem;
    }
    .MuiOutlinedInput-notchedOutline,
    &:hover .MuiOutlinedInput-notchedOutline,
    &:focus-within .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.border1};
    }
    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.brandRed};
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
      -webkit-background-clip: text;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
`;

export const ResetPasswordButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.colors.link};
    padding: 0;
    background: none !important;
    text-transform: capitalize;
    text-decoration: underline;
    font-weight: 600;
  }
`;

export const CountryCodeSelect = styled(Select)`
  && {
    color: ${({ theme }) => theme.colors.black2};

    .MuiOutlinedInput-notchedOutline,
    &:hover .MuiOutlinedInput-notchedOutline,
    &:focus-within .MuiOutlinedInput-notchedOutline {
      border-right: none !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0;
      border-color: ${({ theme }) => theme.colors.border1};
    }
    .MuiSelect-icon {
      margin-top: 0.25rem;
    }
  }
`;

export const CountryCodeMenuItem = styled(StyledMenuItem)`
  && {
    margin-inline: 0;
  }
`;

export const ProfileUploadButton = styled(IconButton)`
  && {
    background: ${({ theme }) => theme.colors.backgroundPrimary} !important;
    border-radius: 50%;
    padding: 0.5rem;
  }
`;

export const SquareContainer = styled("div")({
  width: "10.625rem",
  height: "10.625rem",
  border: "1px solid #ccc",
  position: "relative",
});

export const LogoUploadButton = styled('button')({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const LogoUploadButtonAfter = styled('button')({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',

  '& svg': {
    width: '16px',
    height: '16px', 
    position: "absolute",
    right: '-15px',
    bottom: '-15px',
    background: "linear-gradient(135deg, #1c75bc, #00aeef)!important",
    padding: '10px',
    borderRadius: '20px',

    '& path': {
      fill: "#fff"
    }
  },
});

export const UploadedImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});
