import styled from "styled-components";
import socialBg from "../../../assets/images/social-bg.png";
export const Container = styled.div`
  max-width: calc(100% - 120px);
  width: 100%;
  margin: 4.286rem auto;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const SocialCard = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 30px;
  border: ${({ theme }) => `1px solid ${theme.colors.lightWhite}`};
  background: ${({ theme }) => theme.colors.white2};
  padding: 0 1.857rem;
  box-sizing: border-box;
  margin-top: 7.143rem;
  height: 43.143rem;
  background-image: url(${socialBg});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
`;

export const ImageContainer = styled.div<{isImageAbove?:boolean}>`
  position: relative;
  top: ${(props) => (props.isImageAbove ? "0" : "-5.714rem")};
  width: calc(100% - 6.857rem);
  height: 35.429rem;
  margin: 0 auto;
  border-radius: ${({ isImageAbove }) => (isImageAbove ? "0.857rem 0.857rem 0 0" : "0.857rem")};
  border: ${({ theme }) =>`.8px solid ${theme.colors.border1}`};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 6.4px 9.6px 0px ${({ theme }) => theme.colors.boxShadow};
  overflow: hidden;
`;

export const SocialImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SocialContent = styled.div<{isImageAbove?:boolean}>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: ${(props) => (props.isImageAbove ? "4.571rem" : "-2.857rem")};
  margin-bottom: 1.857rem;
  gap: 1.071rem;
`;

export const ContentTitle = styled.h4`
  color: ${({ theme }) => `${theme.colors.white}`};
  text-align: center;
  font-size: 1.714rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ContentDescription = styled.p`
  color: ${({ theme }) => `${theme.colors.white}`};
  text-align: center;
  font-size: 1.143rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.286rem;
  letter-spacing: 0.036rem;
`;
