import {
  CardContainer,
  CardSubTitle,
  CardTitle,
  DashboardCardButton,
  GrayBgHeader,
  GrayBgHeaderText,
  TrendingBrandWrapper,
  TrendingBrandCommonWrapper,
  TrendingBrandLogoWrapper,
} from "./style";
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "styled-components";
import {
  ArrowRightIcon,
  FavouriteAdseBlackIcon,
  HelpBlackIcon,
  NoDiscoveryTeamsCollectionFoundIcon,
  NoDiscoveryTeamsMembersFoundIcon,
} from "../../assets/Icons";
import { StyledDivider } from "../../globalStyle";
import { useDispatch, useSelector } from "react-redux";
import { discoveryBrandsSelector } from "../../store/discovery/selector";
import CardListWrapper from "../commonComponents/adCard/cardListWrapper";
import { DiscoveryItem } from "../../store/discovery/types";
import AdCard from "../commonComponents/adCard";
import { brandItem, teamMemberDetail } from "../../store/metadata/types";
import TopBrandCard from "../commonComponents/brandCards/topBrandCard";
import { useNavigate } from "react-router-dom";
import routeConstant from "../../utils/routeConstant";
import { DiscoveryReducerAction } from "../../store/discovery/slice";
import {
  dashboardAnalyticsSelector,
  teamMemberSavedAdsSelector,
} from "../../store/dashboardAnalytics/selector";
import { formatAdCount } from "../../utils/helpers";
import { teamDetailsSelector } from "../../store/metadata/selector";
import { useEffect, useState } from "react";
import { DashoardAnalyticsSagaActions } from "../../store/dashboardAnalytics/sagas";
import { DiscoverySagaActions } from "../../store/discovery/sagas";

const ExploreLoungeSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const discoveryBrandLists = useSelector(discoveryBrandsSelector);
  const dashboardAnalytics = useSelector(dashboardAnalyticsSelector);
  const teamDetails = useSelector(teamDetailsSelector);
  const teamMemberSavedAds = useSelector(teamMemberSavedAdsSelector);

  const [selectedTeamMember, setSelectedTeamMember] = useState(
    teamDetails[0]?.members[0]?._id
  );
  const handleExploreLoungeViewAll = () => {
    navigate(routeConstant.discovery);
  };

  const handleTopBrandsViewAll = () => {
    dispatch(DiscoveryReducerAction.setDiscoveryTabIndex(1));
    navigate(`${routeConstant.discovery}#community`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    selectedTeamMember &&
      dispatch(
        DashoardAnalyticsSagaActions.fetchTeamMemberSavedAds(selectedTeamMember)
      );
  }, []);

  const handleViewBrandAds = (brandId: string) => {
    dispatch(
      DiscoverySagaActions.fetchBrandDiscoveryItems({
        brandId,
      })
    );
    navigate(`${routeConstant.discovery}#discovery?${brandId}`);
  };
  const renderViewAllButton = (buttonText: string) => {
    return (
      <DashboardCardButton
        endIcon={<ArrowRightIcon />}
        sx={{
          margin: "0.5rem auto",
          height: "2.25rem",
        }}
        onClick={handleExploreLoungeViewAll}
      >
        {buttonText}
      </DashboardCardButton>
    );
  };

  return (
    <CardContainer sx={{ padding: "1.25rem 0" }}>
      <Grid display={"flex"} justifyContent={"space-between"}>
        <Grid sx={{ margin: "0 1.25rem" }}>
          <CardTitle>Discovery</CardTitle>
          <CardSubTitle>
            {dashboardAnalytics.adSavedTodayCount} New Ads Today!
          </CardSubTitle>
        </Grid>
        <Grid sx={{ margin: "0 1.25rem" }}>
          {renderViewAllButton("View Ads")}
        </Grid>
      </Grid>
      <GrayBgHeader margin="1.25rem 0">
        <GrayBgHeaderText
          sx={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          What your team saved recently
          <HelpBlackIcon />
        </GrayBgHeaderText>
      </GrayBgHeader>
      <Grid container spacing={"1.3rem"} sx={{ paddingLeft: "1.25rem" }}>
        <Grid
          item
          sm={4.85}
          borderRight={`1px solid ${theme.colors.border1}`}
          sx={{
            padding: "0.625rem 1.875rem 1.875rem 0.625rem",
            height: "48.375rem",
          }}
        >
          {teamDetails[0]?.members?.length > 0 ? (
            teamDetails[0]?.members.map(
              (item: teamMemberDetail, index: number) => (
                <TrendingBrandWrapper
                  item
                  sm={12}
                  key={index}
                  sx={{
                    cursor: "pointer",
                    background:
                      selectedTeamMember === item?._id
                        ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                        : "",
                    color:
                      selectedTeamMember === item?._id
                        ? "white"
                        : theme.colors.black2,
                  }}
                  onClick={() => {
                    setSelectedTeamMember(item?._id);
                    dispatch(
                      DashoardAnalyticsSagaActions.fetchTeamMemberSavedAds(
                        item?._id
                      )
                    );
                  }}
                >
                  <TrendingBrandCommonWrapper>
                    <TrendingBrandLogoWrapper>
                      <img
                        src={item?.profileImage}
                        alt="brand"
                        style={{
                          maxWidth: "100%",
                          minHeight: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </TrendingBrandLogoWrapper>
                    <Box>
                      <Typography fontSize="1rem" fontWeight="600">
                        {item?.fullName}
                      </Typography>
                      <Typography
                        fontSize="0.875rem"
                        fontWeight="500"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "13rem",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item?.email}
                      </Typography>
                    </Box>
                  </TrendingBrandCommonWrapper>
                  <TrendingBrandCommonWrapper gap="0.537rem">
                    <span
                      style={{
                        height: "15px",
                        width: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        background: "black",
                      }}
                    >
                      <FavouriteAdseBlackIcon />
                    </span>
                    <p
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "1.371rem",
                        padding: "0",
                        margin: "0",
                      }}
                    >
                      {formatAdCount(item?.totalAds ?? 0)} Saved
                    </p>
                  </TrendingBrandCommonWrapper>
                </TrendingBrandWrapper>
              )
            )
          ) : (
            <Box
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                gap="20px"
                alignItems="center"
              >
                <NoDiscoveryTeamsMembersFoundIcon />
                <Box
                  fontSize="16px"
                  fontWeight={500}
                  width="75%"
                  textAlign="center"
                >
                  You Team will be listed here, Join a Team or Create one
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid
          item
          sm={7.15}
          overflow={"auto"}
          position="relative"
          height={"32rem"}
          sx={{ paddingRight: "1.3rem", height: "48.375rem" }}
        >
          <CardListWrapper columns={{ 350: 1, 1024: 2, 1624: 3 }}>
            {teamMemberSavedAds?.topAds?.length > 0 ? (
              teamMemberSavedAds?.topAds?.map(
                (item: DiscoveryItem, index: number) => (
                  <AdCard
                    item={item}
                    key={item._id}
                    index={index}
                    dataList={teamMemberSavedAds?.topAds}
                    showRedirectIcon={true}
                    hideSaveButton={true}
                    handleViewAd={handleViewBrandAds}
                    locationPathname="/dashboard"
                  />
                )
              )
            ) : (
              <Box
                position="absolute"
                width="95%"
                height="95%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="20px"
                  alignItems="center"
                >
                  <NoDiscoveryTeamsCollectionFoundIcon />
                  <Box
                    fontSize="16px"
                    fontWeight={500}
                    width="95%"
                    textAlign="center"
                  >
                    You can check the Ads saved by your team members here
                  </Box>
                </Box>
              </Box>
            )}
          </CardListWrapper>
        </Grid>
      </Grid>
      <StyledDivider />
      <Grid container spacing={"1.3rem"} sx={{ paddingLeft: "1.25rem" }}>
        <Grid
          item
          sm={4.85}
          sx={{
            borderRight: `1px solid ${theme.colors.border1}`,
            textAlign: "center",
          }}
        ></Grid>
        <Grid item sm={7.15} sx={{ textAlign: "center" }}>
          <DashboardCardButton
            endIcon={<ArrowRightIcon />}
            sx={{
              margin: "0.5rem auto",
            }}
            onClick={handleTopBrandsViewAll}
          >
            View All
          </DashboardCardButton>
        </Grid>
      </Grid>
      <GrayBgHeader>
        <GrayBgHeaderText>Favourite Brands</GrayBgHeaderText>
      </GrayBgHeader>
      <Grid container sx={{ paddingRight: "1rem" }}>
        {discoveryBrandLists?.top10
          .slice(0, 6)
          .map((brand: brandItem, index: number) => (
            <Grid
              item
              xs={12 / 4}
              lg={12 / 5}
              xl={12 / 6}
              key={index}
              sx={{ paddingTop: "1.25rem" }}
            >
              <TopBrandCard
                buttonsToShow={["viewAdsButton"]}
                index={index < 9 ? `0${index + 1}` : `${index + 1}`}
                item={brand}
                handleViewAd={handleViewBrandAds}
              />
            </Grid>
          ))}
      </Grid>
      <StyledDivider sx={{ margin: "1.25rem 0" }} />
      <Box display={"flex"}>
        <DashboardCardButton
          endIcon={<ArrowRightIcon />}
          sx={{
            margin: "0.5rem auto",
          }}
          onClick={handleTopBrandsViewAll}
        >
          View All
        </DashboardCardButton>
      </Box>
    </CardContainer>
  );
};

export default ExploreLoungeSection;
