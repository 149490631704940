import { memo, useState } from "react";
import {
  BrandCardContainer,
  BrandLogoWrapper,
  BrandName,
  FavouriteButton,
  LibraryButton,
  SavedCount,
} from "./style";
import { FavouriteIcon, HeartIcon } from "../../../assets/Icons";
import { CircularProgress, Link, Stack } from "@mui/material";
import FacebookIcon from "../../../assets/images/facebook-icon.png";
import { brandItem } from "../../../store/metadata/types";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import {
  favouriteBrandIdsSelector,
  isLoadingSelector,
} from "../../../store/metadata/selector";
import { formatAdCount } from "../../../utils/helpers";
import { SwipeFileAdIcon } from "../swipeFileAdIcon";

const BrandCard = ({
  item,
  handleViewAd,
}: {
  item: brandItem;
  handleViewAd: Function;
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLoadingState = () => {
    setLoading(false);
  };

  const handleClick = (brandId?: string) => {
    if (handleViewAd) {
      brandId && handleViewAd(brandId);
    }
  };

  const handleViewAdButton = async (id: string) => {
    setLoading(true);
    handleViewAd(id, handleLoadingState);
  };
  const handleFavourite = (brand: brandItem | undefined) => {
    brand && dispatch(MetaDataSagaActions.addBrandToFavourite(brand));
  };
  const metadataLoading = useSelector(isLoadingSelector);
  const favouriteBrandIds = useSelector(favouriteBrandIdsSelector);

  return (
    <BrandCardContainer>
      <Stack direction="row" justifyContent="end">
        <FavouriteButton
          endIcon={
            favouriteBrandIds.includes(item?._id) ? (
              <FavouriteIcon />
            ) : (
              <HeartIcon />
            )
          }
          onClick={() => handleFavourite(item)}
          disabled={metadataLoading}
        />
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        gap="0.75rem"
        marginBottom="1.25rem"
      >
        <BrandLogoWrapper onClick={() => handleClick(item?._id)}>
          <img
            src={item?.logo}
            alt="brand"
            style={{
              width: "3.5rem",
              height: "3.5rem",
              borderRadius: "0.5rem",
              marginBottom: "8px",
            }}
          />
        </BrandLogoWrapper>
        <BrandName fontWeight={600} onClick={() => handleClick(item?._id)}>{item?.name}</BrandName>
        <Stack>
          {/* <BrandGenre>Clothing</BrandGenre> */}
          <SavedCount fontSize="0.9rem">
            <SwipeFileAdIcon />
            {formatAdCount(item?.adsSaved ?? 0)} Saved
          </SavedCount>
        </Stack>
      </Stack>
      <Stack direction="column" alignItems="center" gap="10px">
        <LibraryButton
          sx={{ width: "100%", padding: "0.5rem 0 !important" }}
          onClick={() => handleViewAdButton(item?._id)}
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          View Ads
        </LibraryButton>
        <Link
          style={{
            textDecoration: "none",
            width: "100%",
          }}
          target={"_blank"}
          href={item?.url}
        >
          <LibraryButton
            sx={{
              width: "100%",
            }}
          >
            FB Ad Library
            <img
              src={FacebookIcon}
              alt=""
              style={{
                width: "1.125rem",
                height: "1.125rem",
                marginLeft: "0.5rem",
              }}
            />
          </LibraryButton>
        </Link>
      </Stack>
    </BrandCardContainer>
  );
};

export default memo(BrandCard);
