// TODO: may needed in future.
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const AccessControlRoute = ({ children }: any) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  if (isAuthenticated && isAuthenticated !== null) {
    navigate("/");
  }

  return children;
};

export default AccessControlRoute;
