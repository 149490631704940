import {
  Box,
  Card,
  CardMedia,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ModalProps } from "../../utils/propTypes";
import {
  CloseIconWrapper,
  PlatformIconWrapper,
  StyledOutlinedButton,
} from "../modals/style";
import {
  ArrowOutwardIcon,
  CloseIcon,
  DescriptionIcon,
  DrawerCloseIcon,
  HelpCenterIcon,
  VideoIcon,
} from "../../assets/Icons";
import TabsHeader from "../tabsHeader";
import { memo, useState } from "react";
import { tutorialDrawerTabs } from "../../utils/constants";
import { StyledDivider } from "../../globalStyle";
import { useTheme } from "styled-components";
import ContactImage from "../../assets/images/contact-support-image.png";
import ContactTwitterImage from "../../assets/images/twitter-image.png";

const content: { [key: number]: { videoUrl: string; tldrText: string } } = {
  0: {
    videoUrl: "https://www.youtube.com/embed/O7h2MqJEBKU?si=5PdLjznj537yzkwv",
    tldrText:
      "Swipe Ad is the best way save ads forever and create a living ad swipe file. Your library contains all the ads saved to your account using our Chrome Extension or from Discovery - they are organized into Folders and Boards.",
  },
  1: {
    videoUrl: "https://www.youtube.com/embed/4hAAsH0eXG0?si=f0m0tM7ScXXfJLwk",
    tldrText:
      "Explore lounge is a real-time feed of over 200k ads being saved by other Swipe Lounge users. We highly recommend using the AI search function to search by product name for example “collagen” or targeted ad copy like “Black Friday”.",
  },
};

const TutorialDrawer = ({ open, handleClose }: ModalProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {open && (
        <IconButton
          onClick={handleClose}
          sx={{
            position: "fixed",
            top: '1vh',
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1300,
            marginBottom: "0.5rem",
          }}
        >
          <DrawerCloseIcon />
        </IconButton>
      )}
      <Drawer
        anchor="bottom"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: "15px 15px 0 0",
            height: "84vh",
            padding: "2.5rem 4.375rem",
          },
        }}
      >
        <CloseIconWrapper aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseIconWrapper>
        <Typography
          fontSize={"1.5rem"}
          fontWeight={600}
          color={theme.colors.black2}
          marginBottom={"1rem"}
        >
          Video Tutorials
        </Typography>
        <Typography
          fontSize={"1.25rem"}
          fontWeight={400}
          color={theme.colors.gray1}
        >
          Tips, Tricks and Tutorials to get the most from Swipe Ads
        </Typography>
        <StyledDivider sx={{ margin: "1.875rem 0 1rem 0" }} />
        <Box marginBottom={"1.25rem"}>
          <TabsHeader
            tabIndex={tabIndex}
            data={tutorialDrawerTabs}
            setTabIndex={setTabIndex}
          />
        </Box>
        <Grid container spacing={"4.375rem"}>
          <Grid item xs={6}>
            <Stack gap={"1.25rem"}>
              <Stack direction={"row"} alignItems={"center"} gap="0.5rem">
                <VideoIcon />
                <Typography
                  fontSize={"1.25rem"}
                  fontWeight={600}
                  color={theme.colors.black2}
                >
                  Video Tutorial
                </Typography>
              </Stack>
              <Card
                sx={{
                  width: "100%",
                  height: "28rem",
                  borderRadius: "10px",
                }}
              >
                <CardMedia
                  component="iframe"
                  title="YouTube video"
                  src="https://www.youtube.com/embed/Owih9MUa9EQ"
                  height="100%"
                />
              </Card>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack gap={"1.25rem"}>
              <Stack direction={"row"} alignItems={"center"} gap="0.5rem">
                <DescriptionIcon />
                <Typography
                  fontSize={"1.25rem"}
                  fontWeight={600}
                  color={theme.colors.black2}
                >
                  TLDR
                </Typography>
              </Stack>
              <Typography
                fontSize={"1.125rem"}
                fontWeight={400}
                color={theme.colors.gray1}
                textAlign={"justify"}
              >
                {content[tabIndex].tldrText}
              </Typography>
              <StyledDivider />
              <Stack direction={"row"} alignItems={"center"} gap="0.5rem">
                <HelpCenterIcon />
                <Typography
                  fontSize={"1.25rem"}
                  fontWeight={600}
                  color={theme.colors.black2}
                >
                  More Questions ?
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap="0.5rem">
                <StyledOutlinedButton padding="0.938rem 1.25rem">
                  <PlatformIconWrapper src={ContactImage} alt="" />
                  Contact Support
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    marginLeft={"1.25rem"}
                  >
                    <ArrowOutwardIcon fill="#414042" />
                  </Box>
                </StyledOutlinedButton>
                <StyledOutlinedButton padding="0.938rem 1.25rem">
                  <PlatformIconWrapper src={ContactTwitterImage} alt="" />
                  Connect on Twitter
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    marginLeft={"1.25rem"}
                  >
                    <ArrowOutwardIcon fill="#414042" />
                  </Box>
                </StyledOutlinedButton>
              </Stack>
              <StyledDivider />
            </Stack>
          </Grid>
        </Grid>
      </Drawer>
    </div>
  );
};

export default memo(TutorialDrawer);
