import axios from "axios";
import routeConstant from "./utils/routeConstant";
import { ToastTypes, notify } from "./utils/helpers";

const getToken = () => {
  return localStorage.getItem("AccessToken");
};

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: getToken(),
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const authUrls = [
      `${apiBaseUrl}/auth/login`,
      `${apiBaseUrl}/auth/social/login`,
      `${apiBaseUrl}/auth/signup`,
      `${apiBaseUrl}/auth/forgotPassword`
    ];
    const isLoginUrl = authUrls.includes(error.request.responseURL);
    if (res.status === 401) {
      localStorage.clear();
      window.location.replace(`/${routeConstant.login}`);
    }
    if (!isLoginUrl && !window.location.pathname.includes("myFolders")) {
      notify({ message: res?.data?.message, type: ToastTypes.ERROR });
    }
    console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

export default axiosClient;
