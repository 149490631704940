import {
  Container,
  ItemIcon,
  ItemText,
  ListItemStyled,
  TranScriptContent,
  TranScriptData,
  TranScriptLists,
  TranScriptTitle,
} from "./style";
import { CrystalIcon } from "../../../assets/Icons";
import { TransScriptListItem } from "../../../utils/propTypes";

const ListItem = ({ icon, text }: TransScriptListItem) => (
  <ListItemStyled>
    <ItemIcon>{icon}</ItemIcon>
    <ItemText>{text}</ItemText>
  </ListItemStyled>
);

const TranScriptSection = () => {
  const listItems: TransScriptListItem[] = [
    {
      icon: <CrystalIcon />,
      text: "Lorem ipsum dolor sit amet consectetur. Id erat libero magna eu enim accumsan risus sagittis habitant.",
    },
    {
      icon: <CrystalIcon />,
      text: "Lorem ipsum dolor sit amet consectetur. Id erat libero magna eu enim accumsan risus sagittis habitant.",
    },
  ];

  return (
    <Container>
      <TranScriptContent>
        <TranScriptData>
          <TranScriptTitle>Save and Generate Transcripts</TranScriptTitle>
          <TranScriptLists>
            {listItems.map((item, index) => (
              <ListItem key={index} icon={item.icon} text={item.text} />
            ))}
          </TranScriptLists>
        </TranScriptData>
      </TranScriptContent>
    </Container>
  );
};

export default TranScriptSection;