import React from "react";
import { Select, MenuItem, SelectProps } from "@mui/material";
import { useSelector } from "react-redux";
import { userDetailsSelector } from "../../store/account/selector";

// Extend the SelectProps to include our custom props
interface StyledSelectProps extends Omit<SelectProps, "onChange"> {
  onChange: (value: string) => void;
}

// Create the custom Select component
const TeamSelectDropdown: React.FC<StyledSelectProps> = ({
  onChange,
  ...props
}) => {
  const userCurrentTeamDetail = useSelector(userDetailsSelector);

  return (
    <Select
      value={userCurrentTeamDetail?.currentTeamId || ""}
      onChange={(event) => onChange(event.target.value as string)}
      MenuProps={{
        PaperProps: {
          sx: {
            padding: "0 !important",
            "& .MuiList-root": {
              padding: "0 !important",
            },
          },
        },
      }}
      {...props}
    >
      {userCurrentTeamDetail?.userTeams?.map(
        (item: { _id: string; name: string }) => {
          return (
            <MenuItem key={item._id} value={item._id}>
              {item.name}
            </MenuItem>
          );
        }
      )}
    </Select>
  );
};

export default TeamSelectDropdown;
